import React, { useEffect, useState } from 'react';
import { string, any, bool, shape } from 'prop-types';
import { DynamicRecsWrapper } from './DynamicRecsWrapper';
import { SearchViewedWrapper } from '../search-viewed/SearchViewedWrapper';
import ZoneCard from '../utils/zone-card';
import { dynamicRecsDataModel } from '../dataModel';

const PlpRecsRenderPlaceholder = ({
  isBrowse,
  requestKey,
  keyword,
  showLoading,
  itemIds,
  impressionData,
  browseNValue
}) => {

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const schemeName = isBrowse ? 'plpbrowse_multi' : 'searchViewed';
  const items = itemIds?.length > 0 ? itemIds.toString() : '';

  if (!schemeName || !isMounted) {
    return null;
  }

  return (
    <div data-component="PlpRecsRenderPlaceholder" className="sui-w-full">
      {isBrowse ? (
        <DynamicRecsWrapper
          schemaName={schemeName}
          requestKey={requestKey}
          browseNValue={browseNValue}
          anchorId={items}
          showLoading={showLoading}
          errorBoundary
          impressionData={impressionData}
          serviceType="mutlianchor"
          hydrator={{
            delay: 1500,
            scrollBuffer: 750,
            className: 'sui-grid',
            tag: ZoneCard,
            id: `${schemeName}-${itemIds?.[0]}`
          }}
        />
      ) : (
        <SearchViewedWrapper
          schemaName={schemeName}
          anchorId={keyword}
          requestKey={requestKey}
          showLoading={showLoading}
          errorBoundary
          impressionData={impressionData}
          itemIds={items}
          hydrator={{
            className: 'sui-grid',
            tag: ZoneCard,
            id: `${schemeName}-${itemIds?.[0]}-${keyword}`
          }}
        />
      )}
    </div>
  );
};

PlpRecsRenderPlaceholder.displayName = 'PlpRecsRenderPlaceholder';
PlpRecsRenderPlaceholder.dataModel = dynamicRecsDataModel;

PlpRecsRenderPlaceholder.propTypes = {
  isBrowse: bool,
  showLoading: bool,
  requestKey: string,
  keyword: string,
  // eslint-disable-next-line react/forbid-prop-types
  itemIds: any,
  impressionData: shape({
    id: string,
    component: string,
    type: string
  }),
  browseNValue: string
};

PlpRecsRenderPlaceholder.defaultProps = {
  isBrowse: false,
  showLoading: true,
  requestKey: null,
  keyword: '',
  itemIds: null,
  impressionData: {
    id: '',
    component: '',
    type: ''
  },
  browseNValue: null
};

export { PlpRecsRenderPlaceholder };
