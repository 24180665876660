import React from 'react';
import PropTypes, { string, bool, shape } from 'prop-types';
import { Card, CardMedia } from '@one-thd/sui-atomic-components';

export const ImageWrapper = ({
  leftSpaceClasses,
  imageWidthClasses,
  display,
  componentId,
  fieldId,
  link,
  imageSrc,
  altText,
  lazyLoad = false
}) => {
  return (
    <div
      // TODO: move this into test code
      data-testid={fieldId === 'desktopImage' ? 'image-width-test' : 'test-id' + fieldId}
      /* eslint-disable-next-line max-len */
      className={`sui-grid ${leftSpaceClasses} ${imageWidthClasses} ${display}`}
    >
      <Card
        PaperProps={{
          variant: 'shadow',
          shadow: 'none'
        }}
        CardContentProps={{
          className: 'sui-w-full sui-h-full'
        }}
        disablePadding
        href={link}
        data-contentful-entry-id={componentId}
        data-contentful-field-id={fieldId}
      >
        <CardMedia
          src={imageSrc}
          alt={altText}
          loading={lazyLoad ? 'lazy' : ''}
        />
      </Card>
    </div>
  );
};

ImageWrapper.propTypes = {
  componentId: string.isRequired,
  lazyLoad: bool,
  leftSpaceClasses: string.isRequired,
  imageWidthClasses: string.isRequired,
  display: string.isRequired,
  fieldId: string.isRequired,
  // TODO: Probably make this optional
  link: string.isRequired,
  imageSrc: string.isRequired,
  // TODO: Probably make this optional
  altText: string.isRequired,
};