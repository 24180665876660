import React, { useContext, useEffect } from 'react';
import { func } from 'prop-types';
import {
  Button
} from '@one-thd/sui-atomic-components';
import { CalculatorContext } from '../CalculatorContext';
import { CalculatorInputElement } from '../CalculatorInputElement';
import { CalculateByDimensions } from '../../CalculateByDimensions';

export const WidgetInputs = ({ onSuccess }) => {
  const {
    fields,
    onSubmit,
    resetCalculatorState,
  } = useContext(CalculatorContext);

  useEffect(() => {
    if (fields.length === 0) {
      resetCalculatorState();
    }
  });

  const defaultField = fields[0] ?? {};

  if (fields.length !== 1) {
    return null;
  }

  return (
    <div
      id="flooring-drawer-widget-inputs"
      className={
        'sui-flex sui-grow sui-flex-col sui-gap-4 sui-items-center '
        + 'sui-justify-between md:sui-max-h-80 md:sui-flex-row'
      }
    >
      <CalculateByDimensions selectedArea={0} hideLabel />
      <div className={
        'sui-flex sui-gap-4 sui-self-stretch sui-basis-1/2 sui-flex-row'
        + ' md:sui-grid md:sui-grid-rows-1 md:sui-grid-cols-2 md:sui-gap-8'
      }
      >
        {!defaultField.calcByArea && (
          <>
            <CalculatorInputElement
              index={0}
              labelName="Length"
              propName="length"
              propValue={defaultField.length}
              endAdornment="ft."
              reducedMargin
            />
            <CalculatorInputElement
              index={0}
              labelName="Width"
              propName="width"
              propValue={defaultField.width}
              endAdornment="ft."
              reducedMargin
            />
          </>
        )}
        {defaultField.calcByArea && (
          <>
            <CalculatorInputElement
              index={0}
              labelName="Square Footage"
              propName="sqFootage"
              propValue={defaultField.sqFootage}
              endAdornment="sq. ft."
              reducedMargin
            />
            <div className="sui-basis-1/2" />
          </>
        )}
      </div>
      <Button
        onClick={() => {
          if (onSubmit()) {
            onSuccess();
          }
        }}
        variant="secondary"
        data-testid="estimate-cost"
      >
        <div className="sui-text-nowrap sui-p-4">
          Estimate Cost
        </div>
      </Button>
    </div>
  );
};

WidgetInputs.displayName = 'WidgetInputs';

WidgetInputs.propTypes = {
  onSuccess: func.isRequired,
};

WidgetInputs.defaultProps = {
};