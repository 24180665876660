import React from 'react';
import PropTypes from 'prop-types';
import { Input, Menu } from '@one-thd/sui-atomic-components';
import PriceRangeMenu from './PriceRangeMenu';

const PriceRangeInput = ({
  id,
  placeholder,
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  open,
  anchorRef,
  onScrollDropDown,
  onClickOfDropDown,
  getValue,
  items,
  lowerBound
}) => (
  <section className="sui-flex-1 sui-relative">
    <Input
      id={id}
      data-testid={name}
      placeholder={placeholder}
      name={name}
      inputProps={{ 'aria-label': `price-range-${name}` }}
      type="text"
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      fullWidth
      ref={anchorRef}
      aria-controls={open ? `${name}-menu` : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
    />
    <Menu
      id={`${name}-menu`}
      open={open}
      anchorEl={anchorRef.current}
      onScroll={(_event) => onScrollDropDown(name)}
      MenuListProps={{ 'aria-labelledby': `pf-price-range-${name}` }}
      className="sui-z-tooltip"
    >
      <PriceRangeMenu
        items={items}
        onClickOfDropDown={onClickOfDropDown}
        getValue={getValue}
        lowerBound={lowerBound}
      />
    </Menu>
  </section>
);

PriceRangeInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  anchorRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ])
  }).isRequired,
  onScrollDropDown: PropTypes.func.isRequired,
  onClickOfDropDown: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired
  })).isRequired,
  lowerBound: PropTypes.string.isRequired
};

export default PriceRangeInput;