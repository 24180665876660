/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  DrawerHeader,
  MenuList,
  MenuItem
} from '@one-thd/sui-atomic-components';

const DrawerRefinement = ({ dimension, onItemSelection, onCancel }) => {
  const onSingleItemSelection = (refinement) => {
    onItemSelection({ refinement, dimension });
  };

  return (
    <>
      <DrawerHeader
        data-testid={`drawer-header-${dimension.label}`}
        title={dimension.label}
        onClose={() => onCancel([], 'close')}
      />
      <MenuList data-testid="drawer-body-category">
        {dimension.refinements.map((row, _index) => (
          <>
            <MenuItem key={`${row.refinementKey || row.label}`} onClick={() => onSingleItemSelection(row)}>
              <span className="sui-h-12 sui-w-full sui-flex sui-items-center">
                <Typography align="left">
                  <span onClick={() => onSingleItemSelection(row)}>{row.label}</span>
                </Typography>
              </span>
            </MenuItem>
            <div className="sui-w-full sui-border-primary sui-border-b-1 sui-border-solid" />
          </>
        ))}
      </MenuList>
    </>
  );
};

DrawerRefinement.displayName = 'DrawerRefinement';

DrawerRefinement.propTypes = {
  dimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onItemSelection: PropTypes.func.isRequired
};

export { DrawerRefinement };