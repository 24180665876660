import React, { useState, useContext, Suspense } from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { shouldDisplayPipCalculator, FlooringCalculatorWrapper } from '@thd-olt-component-react/calculator';
import { parseUrl } from '@thd-olt-component-react/product-results';
import { useConfigService, useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import {
  client,
  alias,
  params,
  useDataModel,
  arrayOf as arrayOfType,
  bool as boolType,
  number as numberType,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';
import { DrawerWidget } from './drawer-widget/DrawerWidget';

const CalculatorDrawer = React.lazy(() => import(
  /* webpackChunkName: "calculator-drawer" */'../calculator-drawer/CalculatorDrawer'
));

export const FlooringDrawer = ({ itemId }) => {
  const { path } = useContext(ExperienceContext);
  const { keyword, nValue } = parseUrl({ path });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const estimatorEnabled = useConfigService('fs:estimatorEnabled'); // todo remove
  const { storeId, storeZip: zipCode } = useStore();

  const { data: productData } = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
      storeId,
      zipCode
    },
    skip: !itemId,
    ssr: false
  });

  const { data: searchModelData } = useDataModel('searchModel', {
    variables: {
      keyword,
      navParam: nValue
    },
    ssr: true,
    skip: itemId
  });

  const { product = {} } = productData || {};
  const {
    fulfillment = {},
    info = {},
    installServices = {},
  } = product;

  const taxonomy = itemId ? productData?.product?.taxonomy : searchModelData?.searchModel?.taxonomy;
  const label = taxonomy?.breadCrumbs?.[0]?.label || '';
  const isVinylSheet = taxonomy?.breadCrumbs?.some((element) => element?.label === 'Vinyl Sheet Flooring');

  const isFlooringPLP = !itemId
    && taxonomy?.breadCrumbs?.length > 0
    && taxonomy?.breadCrumbs?.some((element) => element.label === 'Flooring');

  if (
    !label
    || !estimatorEnabled
    || (itemId && !shouldDisplayPipCalculator({ fulfillment, info, installServices }))
    || (!itemId && !isFlooringPLP)) {
    return null;
  }

  const onDrawerClick = () => {
    setDrawerOpen((prev) => !prev);
  };

  return (
    <div
      className="sui-flex sui-w-full"
      data-component="FlooringDrawer"
    >
      <FlooringCalculatorWrapper taxonomy={taxonomy}>
        <DrawerWidget
          label={label}
          onClick={onDrawerClick}
          pageType={itemId ? 'PIP' : 'PLP'}
          isVinylSheet={isVinylSheet}
        />
        { drawerOpen && (
          <Suspense fallback={<div />}>
            <CalculatorDrawer
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              label={label}
            />
          </Suspense>
        )}
      </FlooringCalculatorWrapper>
    </div>
  );
};

FlooringDrawer.displayName = 'FlooringDrawer';

FlooringDrawer.propTypes = {
  itemId: string
};

FlooringDrawer.defaultProps = {
  itemId: null
};

FlooringDrawer.dataModel = {
  clientOnlyProduct: alias('product').params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    fulfillment: client(params({ storeId: stringType(), zipCode: stringType() }).shape({
      fulfillmentOptions: arrayOfType(shapeType({
        type: stringType(),
        fulfillable: boolType(),
      }))
    })),
    info: shapeType({
      pipCalculator: shapeType({
        coverageUnits: stringType(), // Dimensional units Ex: "Panel", "Block", "Sheet", "Bag"
        display: boolType(), // Whether or not to display the calculator
        publisher: numberType(), // units per case
        toggle: stringType(), // "2-Point" (sq. ft.) or "3-Point" (cu. ft.)
        defaultAdditionalCoverage: numberType(), // Whether additional coverage is used by default
        additionalCoveragePercentage: boolType() // The additional coverage percentage
      })
    }),
    installServices: params({ storeId: stringType() }).shape({
      scheduleAMeasure: boolType()
    }),
    taxonomy: shapeType({
      breadCrumbs: arrayOfType(shapeType({
        label: stringType()
      }))
    }),
  }),
  searchModel: params({ keyword: stringType(), navParam: stringType() }).shape({
    taxonomy: shapeType({
      breadCrumbs: arrayOfType(shapeType({
        label: stringType(),
      }))
    })
  })
};