import React, { Suspense } from 'react';
import {
  arrayOf, node, oneOfType, string, shape
} from 'prop-types';
import { Skeleton, SkeletonLine } from '@one-thd/sui-atomic-components';
import { ToasterProvider } from './Toaster/ToasterProvider';
import { FLOORING_DRAWER } from '../../../constants';

const CalculatorProvider = React.lazy(() => import(
  /* webpackChunkName: "calculator-flooringdrawer" */'../CalculatorProvider'
));

const CalculatorPlaceholder = () => {
  return (
    <Skeleton
      PaperProps={{
        variant: 'shadow',
        className: 'sui-grow'
      }}
      grow
    >
      <SkeletonLine fullWidth variant="multi" numberOfLines={2} />
    </Skeleton>
  );
};

export const FlooringCalculatorWrapper = ({ taxonomy, children }) => {
  return (
    <div data-component="FlooringCalculatorWrapper" className="sui-flex sui-grow">
      {typeof window === 'undefined' ? <CalculatorPlaceholder /> : (
        <ToasterProvider>
          <Suspense fallback={<CalculatorPlaceholder />}>
            <CalculatorProvider
              calculatorType={FLOORING_DRAWER}
              taxonomy={taxonomy}
              lineItemName="Area"
            >
              {children}
            </CalculatorProvider>
          </Suspense>
        </ToasterProvider>
      )}
    </div>
  );
};

FlooringCalculatorWrapper.displayName = 'FlooringCalculatorWrapper';

FlooringCalculatorWrapper.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  taxonomy: shape({
    breadCrumbs: arrayOf(shape({
      label: string
    }))
  }).isRequired,
};
