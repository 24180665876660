import React, { useEffect } from 'react';
import { string, number, bool as boolean } from 'prop-types';
import {
  params,
  shape,
  customType,
  typename,
  arrayOf,
  namedFragment,
  string as stringType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { ResourceRenderer } from './ResourceRenderer';

const Resource = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  boldTitle = false,
  lazyLoad = false,
  shouldUseContentPlayer = false
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('resource.ready');
  }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  return (
    <ResourceRenderer
      componentId={componentId}
      componentClass={componentClass}
      componentPosition={componentPosition}
      boldTitle={boldTitle}
      lazyLoad={lazyLoad}
      dataComponent={data?.component}
      shouldUseContentPlayer={shouldUseContentPlayer}
    />
  );
};

Resource.displayName = 'Resource';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  __typename: typename('DamMedia')
});

Resource.propTypes = {
  componentId: string.isRequired,
  componentPosition: number,
  componentClass: string,
  boldTitle: boolean,
  lazyLoad: boolean,
  shouldUseContentPlayer: boolean
};

Resource.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['Resource'])
      .isRequired()
  }).shape({
    Resource: namedFragment({
      inline: true,
      fragmentType: 'Resource'
    }).shape({
      id: stringType(),
      title: stringType(),
      altText: stringType(),
      link: stringType(),
      thumbNailImage: stringType(),
      image: shape({
        damDownloadedContent: shape({
          url: stringType()
        })
      }),
      video: stringType(),
      previewImage: DamMediaFragment,
      description: stringType(),
      eyebrow: stringType()
    }),
  }),
};

export { Resource };
