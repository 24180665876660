import React from 'react';
import PropTypes, { string, bool, shape } from 'prop-types';
import { useImpression, ImpressionProvider } from '@thd-olt-component-react/impression';
import { RichTextWrapper } from './subcomponents/RichTextWrapper';
import { ImageWrapper } from './subcomponents/ImageWrapper';
import {
  getOrientationType,
  getImageAndTextWidthClasses,
  getTextPaddingClasses,
  getDisplayClasses,
  getLeftSpaceClasses,
  getImageUrl
} from './utils/helpers';

const NewspaperRenderer = ({
  componentId,
  lazyLoad = false,
  dataComponent = {},
  richText = '',
  isLivePreview = false
}) => {

  const {
    ref,
    clickID
  } = useImpression({
    data: {
      id: componentId,
      name: 'NewspaperModule',
      component: 'NewspaperModule',
      position: 1,
      type: 'content'
    }
  });

  const link = dataComponent?.link;
  const altText = dataComponent?.altText;
  const damImageAlignmentBehavior = dataComponent?.damImageAlignmentBehavior;
  const damImageWidthBehavior = dataComponent?.damImageWidthBehavior;
  const descriptionAlignment = dataComponent?.descriptionAlignment;

  const { isCentered, isVertical, imageFirst } = getOrientationType(
    damImageAlignmentBehavior,
    descriptionAlignment,
    richText
  );

  const { imageWidthClasses, textWidthClasses } = getImageAndTextWidthClasses(damImageWidthBehavior, isVertical);
  const textPaddingClasses = getTextPaddingClasses(descriptionAlignment);

  const desktopImage = getImageUrl('desktop', dataComponent);
  const tabletImage = getImageUrl('tablet', dataComponent);
  const mobileImage = getImageUrl('mobile', dataComponent);

  // fallback logic:
  // - desktop image is always required
  // - if there is desktop image, tablet image, and mobile image, each breakpoint has unique image
  // - if there is desktop image and tablet image, mobile will use tablet image
  // - if there is desktop image and mobile image, tablet will use desktop image
  // - if there is only desktop image, all 3 breakpoints will use desktop

  const { desktopDisplay, tabletDisplay, mobileDisplay } = getDisplayClasses(tabletImage, mobileImage);

  const {
    leftSpaceClassesDesk,
    leftSpaceClassesTab,
    leftSpaceClassesMob
  } = getLeftSpaceClasses({
    isVertical,
    isCentered,
    mobileDisplay,
    tabletDisplay,
    damImageWidthBehavior
  });

  return (
    <ImpressionProvider
      data={{
        id: componentId,
        name: 'NewspaperModule',
        component: 'NewspaperModule',
        type: 'content'
      }}
    >
      <div
        className="sui-grid sui-grid-cols-12"
        data-component="NewspaperModule"
        ref={ref}
        /* eslint-disable-next-line react/no-unknown-property */
        clickid={clickID}
      >
        {richText && !imageFirst && (
          <RichTextWrapper
            textWidthClasses={textWidthClasses}
            textPaddingClasses={textPaddingClasses}
            componentId={componentId}
            richText={richText}
            isLivePreview={isLivePreview}
          />
        )}
        <ImageWrapper
          leftSpaceClasses={leftSpaceClassesDesk}
          imageWidthClasses={imageWidthClasses}
          display={desktopDisplay}
          componentId={componentId}
          fieldId="desktopImage"
          link={link}
          altText={altText}
          lazyLoad={lazyLoad}
          imageSrc={desktopImage}
        />
        {tabletDisplay
          && (
            <ImageWrapper
              leftSpaceClasses={leftSpaceClassesTab}
              imageWidthClasses={imageWidthClasses}
              display={tabletDisplay}
              componentId={componentId}
              fieldId="tabletImage"
              link={link}
              altText={altText}
              lazyLoad={lazyLoad}
              imageSrc={tabletImage}
            />
          )}
        {mobileDisplay
          && (
            <ImageWrapper
              leftSpaceClasses={leftSpaceClassesMob}
              imageWidthClasses={imageWidthClasses}
              display={mobileDisplay}
              componentId={componentId}
              fieldId="mobileImage"
              link={link}
              altText={altText}
              lazyLoad={lazyLoad}
              imageSrc={mobileImage}
            />
          )}
        {richText && imageFirst && (
          <RichTextWrapper
            textWidthClasses={textWidthClasses}
            textPaddingClasses={textPaddingClasses}
            componentId={componentId}
            richText={richText}
            isLivePreview={isLivePreview}
          />
        )}
      </div>
    </ImpressionProvider>
  );
};

NewspaperRenderer.propTypes = {
  componentId: string.isRequired,
  lazyLoad: bool,
  isLivePreview: bool,
  richText: PropTypes.oneOfType([
    string,
    PropTypes.object
  ]).isRequired,
  dataComponent: shape({
    id: string,
    altText: string,
    link: string,
    description: string,
    damImageAlignmentBehavior: string,
    damImageWidthBehavior: string,
    descriptionAlignment: string,
    desktopImage: shape({}),
    tabletImage: shape({}),
    mobileImage: shape({})
  })
};

export { NewspaperRenderer };