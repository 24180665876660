import React, { useContext, forwardRef } from 'react';
import PropTypes, { bool, string } from 'prop-types';
import classNames from 'classnames';
import { Button } from '@one-thd/sui-atomic-components';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { ProductResultsContext } from './ProductResultsContext';
import '../styles/results-wrapped.scss';

const ResultsWrapped = forwardRef(({ children = null }, ref) => {
  const { data, loading } = useContext(ProductResultsContext);
  const theme = useTheme(ResultsWrapped) || {};
  const { showHomePageButton, noProductResultsMessage } = theme.props;

  const wrappedClasses = classNames('results-wrapped', {
    'results-wrapped--fade': (loading && data)
  });
  const displayMessage = !loading
    && data
    && data?.searchModel?.metadata
    && !data?.searchModel?.metadata.searchRedirect
    && data?.searchModel?.products?.length === 0;

  return (
    <div className={wrappedClasses} data-component="ResultsWrapped" ref={ref}>
      {displayMessage ? (
        <div className="results-wrapped--no-products">
          {noProductResultsMessage}
          {showHomePageButton && (
            <Button variant="primary" onClick={() => { window.location.href = '/'; }}>
              Return to Home Page
            </Button>
          )}
        </div>
      ) : children}
    </div>
  );
});

ResultsWrapped.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ResultsWrapped.defaultProps = {
  children: null
};

ResultsWrapped.displayName = 'ResultsWrapped';

ResultsWrapped.themeProps = {
  showHomePageButton: bool,
  noProductResultsMessage: string
};

ResultsWrapped.defaultThemeProps = {
  showHomepageButton: false,
  noProductResultsMessage: 'Sorry, there are no products available online or in your local store. '
    + 'Please use our Store Finder to select another local store.'
};

export { ResultsWrapped };
