import React from 'react';
import {
  string, shape, bool, func, oneOfType, number
} from 'prop-types';
import classNames from 'classnames';
import { ConfirmedFilled } from '@one-thd/sui-icons';
import { Tile, TileContent, TileText, Typography } from '@one-thd/sui-atomic-components';

const MultiStateDrawerRefinement = ({
  refinement, onItemSelection, isRefinementSelected
}) => {
  const onMultiItemSelection = () => {
    onItemSelection({ refinement });
  };

  const selectedClass = 'sui-border-2 sui-border-solid sui-border-strongest hover:sui-bg-subtle';

  const baseClass = classNames(
    'sui-lab-btn-base',
    'sui-bg-primary',
    'sui-border-solid',
    'sui-border-1',
    'sui-rounded-base',
    'sui-text-primary',
    'sui-border-1',
    'sui-p-[7px]',
    'sui-px-2',
    'sui-border-input-primary',
    'sui-flex-grow'
  );

  const hoverClass = classNames(
    'hover:sui-outline',
    'hover:sui-outline-2',
    'hover:-sui-outline-offset-2',
    'hover:sui-outline-button-hover-accent',
    'focus-visible:sui-outline',
    'focus-visible:sui-outline-input-focus',
    'focus-visible:sui-outline-4',
    'focus-visible:-sui-outline-offset-4'
  );

  const containerClass = classNames(
    'sui-flex',
    'sui-flex-wrap',
    'sui-flex-col',
    'sui-gap-2',
    'sui-mb-3',
    'multi-select-refinement',
    {
      'multi-select-refinement--selected': isRefinementSelected
    }
  );

  return (
    <div
      className={containerClass}
      data-testid="multi-select-refinement"
    >
      <Tile
        grow
        value={refinement.label}
        aria-label={refinement.label}
        className={classNames(
          baseClass,
          { [hoverClass]: !isRefinementSelected },
          { [selectedClass]: isRefinementSelected }
        )}
        onClick={onMultiItemSelection}
      >
        <TileContent grow alignItems="start">
          <TileText>
            <div className="sui-py-1">
              <Typography
                color="primary"
                align="left"
              >
                {refinement.label}
                { isRefinementSelected && (
                  <span className="sui-absolute sui-mr-2 sui-mt-2px sui-right-0">
                    <ConfirmedFilled size="small" />
                  </span>
                )}
              </Typography>
            </div>
          </TileText>
        </TileContent>
      </Tile>
    </div>
  );
};

MultiStateDrawerRefinement.displayName = 'DrawerRefinement';
MultiStateDrawerRefinement.propTypes = {
  refinement: shape({
    label: string,
    recordCount: oneOfType([string, number]),
    refinementKey: string,
    isRefinementSelected: bool,
    url: string
  }).isRequired,
  onItemSelection: func.isRequired,
  isRefinementSelected: bool
};

MultiStateDrawerRefinement.defaultProps = {
  isRefinementSelected: false
};

export { MultiStateDrawerRefinement };