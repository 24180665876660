import React, {
  createContext,
  useContext,
  useMemo,
  useState
} from 'react';
import { string, bool, node } from 'prop-types';
import { WIDGET_MAPPING } from '../constants';

const FitCompatibilityContext = createContext({ type: '', drawerPosition: '' });
export const useFitCompatibility = () => useContext(FitCompatibilityContext);

export const FitCompatibilityProvider = ({
  isLoading,
  type,
  drawerPosition,
  children
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerIndex, setDrawerIndex] = useState(0);

  const {
    header = '',
    subtext = '',
    canHaveArrow = false,
    isPip = false,
    isPlp = false,
    isMini = false
  } = WIDGET_MAPPING[type];

  const value = useMemo(() => ({
    isLoading,
    type,
    drawerPosition,
    header,
    subtext,
    canHaveArrow,
    isPip,
    isPlp,
    isMini,
    drawerOpen,
    setDrawerOpen,
    drawerIndex,
    setDrawerIndex
  }), [
    isLoading,
    type,
    drawerPosition,
    header,
    subtext,
    canHaveArrow,
    isPip,
    isPlp,
    isMini,
    drawerOpen,
    setDrawerOpen,
    drawerIndex,
    setDrawerIndex
  ]);

  return (
    <FitCompatibilityContext.Provider value={value}>
      {children}
    </FitCompatibilityContext.Provider>
  );
};

FitCompatibilityProvider.propTypes = {
  isLoading: bool.isRequired,
  type: string.isRequired,
  drawerPosition: string.isRequired,
  children: node
};

FitCompatibilityProvider.defaultProps = {
  children: null
};
