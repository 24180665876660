import React, { useEffect, useState, useContext } from 'react';
import { useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import { useDataModel, useLazyDataModel } from '@thd-nucleus/data-sources';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { string, bool, number, shape } from 'prop-types';
import className from 'classnames';
import { dynamicRecsDataModel } from '../dataModel';
import {
  isSearchLoading,
  getAppId,
  getLoyaltyMembershipInput,
  impressionContainerNameParser,
  getDrecsImpressionID,
  getDrecsimpressionType
} from '../utils/helpers';
import { ProductPodV7Carousel } from '../core/ProductPodV7Carousel';

const SearchViewedRecs = ({
  anchorId,
  anchorImage,
  maxItems,
  requestKey,
  showLoading,
  hideFavorites,
  itemIds,
  impressionData,
  name
}) => {
  const { storeId, storeZip: zipCode, membershipInformation } = useStore();
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const [data, setData] = useState(null);
  const [searchSchema, setSearchSchema] = useState(null);
  const [impressionName, setImpressionName] = useState('');
  const schemaName = 'searchViewed';
  const multiSchemaName = 'plpsearch_multi';

  const searchOpts = {
    variables: {
      anchorId,
      appId: getAppId(channel, isConsumerApp),
      apiName: schemaName,
      dataSource: schemaName,
      loyaltyMembershipInput: getLoyaltyMembershipInput(membershipInformation),
      key: requestKey,
      cType: null,
      timeStamp: null,
      storeId,
      zipCode,
      serviceType: null,
      skipInstallServices: true,
      maxResults: null,
      nvalue: null
    }
  };
  const { data: searchData, loading, error } = useDataModel('recs', searchOpts);

  const { recs } = searchData || {};
  const multiOpts = {
    manual: true,
    variables: {
      anchorId: itemIds,
      appId: getAppId(channel, isConsumerApp),
      apiName: multiSchemaName,
      dataSource: multiSchemaName,
      loyaltyMembershipInput: getLoyaltyMembershipInput(membershipInformation),
      key: requestKey,
      serviceType: 'mutlianchor',
      storeId,
      zipCode,
      skipInstallServices: true,
      maxResults: null,
      cType: null,
      timeStamp: null,
      nvalue: null
    }
  };

  const [
    getMultiProductSkus,
    {
      data: multiData,
      loading: multiLoading,
      error: multiError
    }
  ] = useLazyDataModel('recs', multiOpts);

  const shouldRequestFallback = () => {
    return !loading && (searchData || error) && !multiLoading && !multiError && !multiData && !data;
  };

  useEffect(() => {
    if (searchData?.recs?.products?.length > 0) {
      setData(searchData);
      setSearchSchema(recs?.metadata?.apiName);
      setImpressionName(searchData?.recs?.metadata?.title);
    } else if (shouldRequestFallback()) {
      getMultiProductSkus();
    }
  }, [searchData]);

  useEffect(() => {
    if (!data && multiData?.recs?.products?.length > 0) {
      setData(multiData);
      setSearchSchema(multiData?.recs?.metadata?.apiName);
      setImpressionName(multiData?.recs?.metadata?.title);
    }
  }, [multiData]);

  const wrapperClasses = className(
    'dynamic-recs',
    'sui-w-full',
    'sui-p-4',
    searchSchema,
    {
      loading: showLoading && loading
    }
  );

  if (multiError || (!loading && !multiLoading && !data
    && multiData !== undefined && !searchData?.recs && !multiData?.recs)) {
    return null;
  }
  const analyticsAnchorProduct = searchSchema === 'searchViewed' ? anchorId : itemIds?.split(',')[0];

  return (
    <ImpressionProvider
      data={{
        id: getDrecsImpressionID(impressionData),
        component: 'searchViewed',
        name: impressionContainerNameParser(impressionName || null),
        type: getDrecsimpressionType(impressionData),
        recType: searchSchema || '',
        position: '',
        category: '',
        categoryPosition: '',
        ...(name ? { sectionSlotID: name } : {}),
      }}
    >
      <div
        id={searchSchema}
        className={wrapperClasses}
        data-type="container"
        data-component="SearchViewedRecs"
      >
        <meta data-prop="name" content={searchSchema} />
        <ProductPodV7Carousel
          anchorImage={anchorImage}
          loading={isSearchLoading(data, searchData, multiData, loading, multiLoading)}
          hideFavorites={hideFavorites}
          itemClass="fixed-item"
          scheme={searchSchema}
          analyticsAnchorProductSku={analyticsAnchorProduct}
          membershipInformation={membershipInformation}
          storeId={storeId}
          zipCode={zipCode}
          maxItems={maxItems}
          showLoading={showLoading}
          data={data?.recs}
          parent="plp-recs-placeholder"
        />
      </div>
    </ImpressionProvider>
  );
};

SearchViewedRecs.propTypes = {
  anchorId: string.isRequired,
  anchorImage: string,
  hideFavorites: bool,
  requestKey: string,
  maxItems: number,
  showLoading: bool,
  itemIds: string,
  impressionData: shape({
    id: string,
    component: string,
    type: string
  }),
  name: string
};

SearchViewedRecs.defaultProps = {
  anchorImage: null,
  hideFavorites: true,
  requestKey: null,
  showLoading: true,
  maxItems: 16,
  itemIds: '',
  impressionData: {
    id: null,
    component: null,
    type: null
  },
  name: ''
};

SearchViewedRecs.dataModel = dynamicRecsDataModel;

SearchViewedRecs.displayName = 'SearchViewedRecs';

export default SearchViewedRecs;
