import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSessionStorage } from '@thd-olt-global/thd-storage-utils';
import { Toggle } from '@one-thd/sui-atomic-components';
import { toggleProjectPriceAnalytics } from '../../../../analytics';

export const ProjectToggle = ({ pageType }) => {
  const [toggled, setToggled] = useSessionStorage('flooringPicker_projectPrice');

  const triggerAnalyticsAndEvents = (value) => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('flooringPicker.flooringPicker_toggle', { toggleOn: value });
    toggleProjectPriceAnalytics({ isToggledOn: value, pageType });
  };

  // TODO: Change price and other components to use useSessionStorage hook instead of
  // 'flooringPicker.flooringPicker_toggle' event
  useEffect(() => {
    if (toggled === undefined) {
      setToggled(true);
    }
  }, []);

  useEffect(() => {
    triggerAnalyticsAndEvents(toggled);
  }, [toggled]);

  const handleToggleChange = (event) => {
    setToggled(event.target.checked);
  };

  return (
    <div className="sui-self-end sui-flex sui-flex-row sui-items-center gap-1">
      {toggled !== undefined && (
        <Toggle
          id="project-price-toggle"
          checked={toggled}
          onChange={handleToggleChange}
        />
      )}

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="project-price-toggle">
        Show Project Price
      </label>
    </div>
  );
};

ProjectToggle.propTypes = {
  pageType: PropTypes.oneOf(['PIP', 'PLP'])
};

ProjectToggle.defaultProps = {
  pageType: 'PIP'
};