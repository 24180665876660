import React from 'react';
import {
  string as stringType,
  object,
  shape,
  bool
} from 'prop-types';
import { QueryProvider, useDataModel, extend } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { DiscoveryZoneCarousel } from './DiscoveryZoneCarousel';
import DynamicRecs from '../dynamic-recs/DynamicRecs';
import { discoveryZonesModel as dataModel } from '../dataModel';
import { impressionContainerNameParser } from '../utils/helpers';

export const DiscoveryZones = (props) => {

  const {
    schemaName,
    zoneId,
    nValue,
    storeId: searchStoreId,
    data: dataProp,
    loading: loadingProp
  } = props;

  const titleMaxLine = 3;
  let searchModel = null;
  let zoneData = {};
  const { storeId, storeZip: zipCode, isLocalized, membershipInformation } = useStore();

  // { data, error, loading, variables }
  const searchModelResponse = useDataModel('searchModel', {
    variables: {
      navParam: nValue,
      storeId: searchStoreId || storeId,
      dataSource: schemaName
    },
    skip: !isLocalized || dataProp || loadingProp,
    ssr: false
  });

  const data = dataProp || searchModelResponse.data;
  const loading = loadingProp || searchModelResponse.loading;

  if (data) {
    searchModel = data.searchModel;
    zoneData = searchModel?.discoveryZones
      ?.find((discoveryZone) => discoveryZone.metadata.zone === zoneId);
  }

  if (!zoneData) {
    return null;
  }

  const mountedFn = ({ mounted }) => {
    if (!isLocalized) {
      return false;
    }
    if (loading) {
      return false;
    }
    return mounted;
  };

  if (!zoneData?.products && isLocalized) {
    return (
      <QueryProvider cacheKey="trending-products-plp" mounted={mountedFn}>
        <DynamicRecs
          anchorId={`N-${nValue}`}
          hideATC
          hideSwatches
          hideFavorites
          requestKey="aGAQFG4j6QtVTSWqujfFYWeIU6BR5Mee"
          schemaName="trending_products"
          parent="discovery-zones-trendingproducts"
          zoneName={zoneData?.metadata?.zoneTitle}
          zonePosition={zoneData?.metadata?.zone}
          checkMinimumProducts
          brandTitleMaxLine={titleMaxLine}
        />
      </QueryProvider>
    );
  }

  const anchorIdForAnalytics = `N-${nValue}`;

  return (
    <div
      id="discoveryZones"
      data-type="container"
      data-component={`discoveryZones-${zoneId}`}
      className="sui-pb-0 sui-pt-0 sui-mb-5 sui-p-4 sui-w-full sui-bg-primary"
    >
      <meta data-prop="name" content="discoveryZones" />
      <ImpressionProvider
        data={{
          id: '',
          component: 'DiscoveryZones',
          name: impressionContainerNameParser(zoneData?.metadata?.zoneTitle),
          type: 'product'
        }}
      >
        <DiscoveryZoneCarousel
          itemClass="fixed-item"
          title={zoneData?.metadata?.zoneTitle}
          scheme={schemaName}
          storeId={searchStoreId || storeId}
          membershipInformation={membershipInformation}
          zipCode={zipCode}
          analyticsAnchorProductSku={anchorIdForAnalytics}
          brandTitleMaxLine={titleMaxLine}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          data={zoneData}
          loading={loading || !isLocalized}
          mountedFn={mountedFn}
        />
      </ImpressionProvider>
    </div>

  );
};

DiscoveryZones.displayName = 'DiscoveryZones';
DiscoveryZones.dataModel = extend({}, dataModel, DiscoveryZoneCarousel);
DiscoveryZones.propTypes = {
  schemaName: stringType.isRequired,
  storeId: stringType,
  zoneId: stringType.isRequired,
  keyword: stringType,
  // eslint-disable-next-line react/forbid-prop-types
  searchModelData: object,
  nValue: stringType,
  data: shape({}),
  loading: bool
};

DiscoveryZones.defaultProps = {
  keyword: '',
  nValue: '',
  searchModelData: null,
  storeId: '',
  data: null,
  loading: false
};
