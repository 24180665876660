import React from 'react';
import {
  bool, object, shape, string as stringType
} from 'prop-types';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { discoveryZonesModel as dataModel } from '../dataModel';
import { LoadingPlaceholder } from '../core/LoadingPlaceholder';
import { DiscoveryZones } from './DiscoveryZones';

const txtPlaceholderLines = 3;
const itemsCount = 6;

const placeholder = (<LoadingPlaceholder txtPlaceholderLines={txtPlaceholderLines} itemsCount={itemsCount} />);

const HydratedDiscoveryZones = withHydrator({
  id: 'discovery-zones',
  scrollBuffer: 750,
  delay: 2000,
  preserveCtxVal: 'clientStore',
  placeholder
}, DiscoveryZones);

const DynamicDiscoveryZones = withDynamicComponent(HydratedDiscoveryZones);
export const DiscoveryZonesWrapper = withErrorBoundary(DynamicDiscoveryZones);

const propTypes = {
  schemaName: stringType.isRequired,
  storeId: stringType,
  zoneId: stringType.isRequired,
  keyword: stringType,
  // eslint-disable-next-line react/forbid-prop-types
  searchModelData: object,
  nValue: stringType,
  data: shape({}),
  loading: bool
};

const defaultProps = {
  keyword: '',
  nValue: '',
  searchModelData: null,
  storeId: '',
  data: null,
  loading: false
};

DiscoveryZonesWrapper.propTypes = propTypes;
DiscoveryZonesWrapper.defaultProps = defaultProps;
DiscoveryZonesWrapper.dataModel = dataModel;
DiscoveryZonesWrapper.displayName = 'DiscoveryZonesWrapper';
