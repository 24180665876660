import React, { useState, useEffect } from 'react';
import { ProductResultsContext } from './ProductResultsContext';

const ProductResults = (props) => {
  const { children = null, data: propData, error, loading, responseLoading } = props; // eslint-disable-line
  const [data, setData] = useState(propData);

  useEffect(() => {
    setData(propData);
  }, [propData]);

  const resp = {
    data,
    error,
    loading,
    responseLoading,
    setData
  };

  return (
    <ProductResultsContext.Provider value={resp}>
      {children}
    </ProductResultsContext.Provider>
  );
};

export { ProductResults };