import {
  params,
  arrayOf,
  string,
  namedFragment,
  fragment,
  shape,
  customType,
  number,
  typename
} from '@thd-nucleus/data-sources';
import { BrandHero } from '@thd-olt-component-react/hero';
import { Resource } from '@thd-olt-component-react/resource';
import { Spotlight } from '@thd-olt-component-react/spotlight';

const targetingTypeShape = shape({
  AdobeTarget: namedFragment({
    inline: true,
    fragmentType: 'AdobeTarget',
  }).shape({
    id: string(),
    mbox: string(),
    __typename: typename('AdobeTarget')
  }),
  MultiArmBandit: namedFragment({
    inline: true,
    fragmentType: 'MultiArmBandit',
  }).shape({
    id: string(),
    campaignId: string(),
    __typename: typename('MultiArmBandit')
  }),
  Innervate: namedFragment({
    inline: true,
    fragmentType: 'Innervate',
  }).shape({
    id: string(),
    tagID: string(),
    adkey: string(),
    __typename: typename('Innervate')
  })
});

const DynamicSlot = {};
DynamicSlot.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['Slot'])
      .isRequired(),
  }).shape({
    Slot: namedFragment({
      inline: true,
      fragmentType: 'Slot',
      fragmentAlias: 'DynamicSlot'
    }).shape({
      componentName: string(),
      id: string(),
      name: string(),
      slotNumber: number(),
      content: shape({
        ...Spotlight.dataModel.component,
        ...Resource.dataModel.component,
      })
    })
  })
};

const HeroSlot = {};
HeroSlot.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['Slot'])
      .isRequired(),
  }).shape({
    Slot: namedFragment({
      inline: true,
      fragmentType: 'Slot',
      fragmentAlias: 'HeroSlot'
    }).shape({
      componentName: string(),
      id: string(),
      name: string(),
      slotNumber: number(),
      content: shape({
        ...BrandHero.dataModel.brandedComponent,
      })
    })
  })
};

const DynamicComponentSelector = {};
DynamicComponentSelector.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['ComponentSelector']).isRequired(),
  }).shape({
    ComponentSelector: namedFragment({
      inline: true,
      fragmentType: 'ComponentSelector',
      fragmentAlias: 'DynamicComponentSelector'
    }).shape({
      componentName: string(),
      id: string(),
      targetingType: targetingTypeShape,
      defaultComponent: arrayOf(
        shape({
          ...DynamicSlot.dataModel.component
        })
      ),
      variations: arrayOf(
        shape({
          ...DynamicSlot.dataModel.component
        })
      ),
    }),
  }),
};

const HeroComponentSelector = {};
HeroComponentSelector.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['ComponentSelector']).isRequired(),
  }).shape({
    ComponentSelector: namedFragment({
      inline: true,
      fragmentType: 'ComponentSelector',
      fragmentAlias: 'HeroComponentSelector'
    }).shape({
      componentName: string(),
      id: string(),
      targetingType: targetingTypeShape,
      defaultComponent: arrayOf(
        shape({
          ...HeroSlot.dataModel.component
        })
      ),
      variations: arrayOf(
        shape({
          ...HeroSlot.dataModel.component
        })
      ),
    }),
  }),
};

export {
  DynamicComponentSelector,
  DynamicSlot,
  HeroComponentSelector,
  HeroSlot
};
