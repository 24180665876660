import React from 'react';
import { VideoPlayer } from '@thd-olt-component-react/video-player';
import { string, bool } from 'prop-types';

const CLASS_NAME = 'sui-object-cover sui-w-full sui-aspect-video';

export default function VideoPlayerWrap({
  videoUrl = '',
  componentId,
  shouldUseContentPlayer = false
}) {
  const isYoutubeVideo = /youtu(be\.com|\.be)/i.test(videoUrl);

  const videoSrcs = {
    videoId: undefined,
    videoSrc: undefined
  };

  if (isYoutubeVideo) {
    const id = (videoUrl.match(/[a-z0-9\-_]{10,}/i) || [''])[0];
    videoSrcs.videoSrc = `https://www.youtube.com/embed/${id}`;

  } else {
    videoSrcs.videoId = (videoUrl.match(/video\/[0-9]{1,}/i) || [''])[0].replace(/video\//i, '');
  }

  return (
    <div
      className={CLASS_NAME}
      data-contentful-entry-id={componentId}
      data-contentful-field-id="video"
    >
      <VideoPlayer
        className={CLASS_NAME}
        videoId={videoSrcs.videoId}
        videoSrc={videoSrcs.videoSrc}
        aspectRatio={16 / 9}
        shouldUseContentPlayer={shouldUseContentPlayer}
      />
    </div>
  );
}

VideoPlayerWrap.propTypes = {
  videoUrl: string,
  componentId: string.isRequired,
  shouldUseContentPlayer: bool
};
