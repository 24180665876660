import {
  arrayOf,
  bool,
  customType,
  extend,
  fragment,
  number,
  params,
  shape,
  string,
  typename,
  namedFragment
} from '@thd-nucleus/data-sources';
import { AnchorLinks } from '@thd-olt-component-react/anchor-links';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { CalloutCard } from '@thd-olt-component-react/callout_card';
import { CapabilityCard } from '@thd-olt-component-react/capability_card';
import { ContentAccordion } from '@thd-olt-component-react/content-accordion';
import { ContentGalleryCard } from '@thd-olt-component-react/content-gallery-card';
import { Endcap } from '@thd-olt-component-react/endcap';
import { BrandHero } from '@thd-olt-component-react/hero';
import {
  MedioInline,
  PiccolaPencil,
  MedioComposableBanner,
  PiccolaComposableBanner
} from '@thd-olt-component-react/internal-marketing-banner';
import { NewspaperModule } from '@thd-olt-component-react/newspaper-module';
import { ProductShelf } from '@thd-olt-component-react/product-shelf';
import { Resource } from '@thd-olt-component-react/resource';
import { SideNavigation } from '@thd-olt-component-react/side-navigation';
import { Spotlight, SpotlightContainer } from '@thd-olt-component-react/spotlight';
import { SpotlightRichText } from '@thd-olt-component-react/spotlight_rich_text';
import { PromoVisualNavigation, VisualNavigation } from '@thd-olt-component-react/visual-navigation';
import {
  DynamicComponentSelector,
  DynamicSlot,
  HeroComponentSelector,
  HeroSlot
} from './personalization';

// EmtDescription doesn't have a data model, so is need to define
const EmtDescription = {};
EmtDescription.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['Description_New']).isRequired(),
  }).shape({
    Description_New: fragment().shape({
      id: string(),
      title: string(),
      descriptionText: string()
    }),
  }),
};

const EndCapData = {};
EndCapData.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['EndCap']).isRequired(),
  }).shape({
    EndCap: fragment().shape({
      id: string(),
      __typename: string()
    })
  })
};

const dynamicSectionsFragment = {
  id: string(),
  title: string(),
  isCarousel: bool(),
  anchorId: string(),
  components: arrayOf(shape({
    ...AnchorLinks.dataModel.component,
    ...BrandHero.dataModel.brandedComponent,
    ...CalloutCard.dataModel.component,
    ...CapabilityCard.dataModel.component,
    ...DynamicComponentSelector.dataModel.component,
    ...ContentAccordion.dataModel.component,
    ...ContentGalleryCard.dataModel.component,
    ...EmtDescription.dataModel.component,
    ...MedioInline.dataModel.component,
    ...MedioComposableBanner.dataModel.component,
    ...NewspaperModule.dataModel.component,
    ...PiccolaPencil.dataModel.component,
    ...PiccolaComposableBanner.dataModel.component,
    ...ProductShelf.dataModel.component,
    ...PromoVisualNavigation.dataModel.component,
    ...Resource.dataModel.component,
    ...Spotlight.dataModel.component,
    ...SpotlightContainer.dataModel.specializedContainer,
    ...SpotlightRichText.dataModel.component,
    ...VisualNavigation.dataModel.component,
    ...EndCapData.dataModel.component
  })),
  __typename: string(),
};

const hybridPLPDataModel = fragment().shape({
  id: string(),
  pageType: string(),
  title: string(),
  // you need this here too because the query is shaped at a lower level
  // it doesnt magically know how to merge the two heroCarousels because the nesting is odd
  heroCarousel: shape({
    __typename: typename('HeroCarousel'),
    id: string(),
    isCarousel: bool(),
    isAutoplay: bool(),
    components: arrayOf(shape({
      ...BrandHero.dataModel.brandedComponent,
      ...HeroComponentSelector.dataModel.component,
    })),
  }),
  dynamicSections: arrayOf(shape({ ...dynamicSectionsFragment }))
});

const breadCrumbsDataModel = fragment().shape({
  __typename: string(),
  breadcrumbItem: shape({
    BreadcrumbItem: fragment().shape({
      label: string(), url: string()
    })
  })
});

const seoDataModel = shape({
  canonicalURL: string(),
  description: string(),
  facebookAdmins: string(),
  siteName: string(),
  title: string(),
  type: string(),
  linkData: string(),
  scriptData: string(),
  no_index: string(),
  no_follow: string()
});

export const hybridLayoutDataModel = extend({
  layouts: params({
    slug: string().isRequired(),
    customerType: string(),
    isHybrid: bool(),
  }).shape({
    id: string(),
    slug: string(),
    type: string(),
    title: string(),
    breadcrumbs: shape({
      __typename: string(), Breadcrumbs: breadCrumbsDataModel
    }),
    seo: seoDataModel,
    content: shape({
      __typename: string(),
      HybridPLPLayout: hybridPLPDataModel
    }),
  }),
},
// you need this here too because the query is asked for at the root level
Breadcrumbs,
AnchorLinks,
BrandHero,
CalloutCard,
CapabilityCard,
ContentAccordion,
ContentGalleryCard,
Endcap,
MedioInline,
MedioComposableBanner,
NewspaperModule,
PiccolaComposableBanner,
PiccolaPencil,
ProductShelf,
PromoVisualNavigation,
Resource,
SideNavigation,
Spotlight,
SpotlightContainer,
SpotlightRichText,
VisualNavigation,
DynamicComponentSelector,
DynamicSlot,
HeroComponentSelector,
HeroSlot,
);
