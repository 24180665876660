import React, { useContext } from 'react';
import {
  string as stringType,
  object,
  shape,
  bool
} from 'prop-types';
import { useDataModel, extend, QueryContext } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { BuyItAgainCarousel } from './BuyItAgainCarousel';
import { dataModel } from './buyItAgainDataModel';
import { isLoading } from '../utils/helpers';

const CoreBuyItAgain = (props) => {

  const {
    anchorId,
    schemaName,
    nValue,
    data: dataProp,
    loading: loadingProp,
    zipCode
  } = props;

  let searchModelData = {};
  const { storeId, isLocalized, membershipInformation } = useStore();
  const { svocID } = useThdCustomer() || {};
  const { isClientResolved } = useContext(QueryContext);
  const searchModelResponse = useDataModel('searchModel', {
    variables: {
      navParam: nValue,
      additionalSearchParams: {
        svocID
      },
      storeId,
      zipCode
    },
    skip: !isLocalized || dataProp || loadingProp
  });
  const resolved = isClientResolved({ queryName: 'searchModel' });
  const loader = !resolved;

  const data = dataProp || searchModelResponse.data;
  if (data) {
    searchModelData = data.searchModel;
  }

  const anchorIdForAnalytics = anchorId?.split(',')[0];

  // this is explicitly added because we are not using loader func.
  if (loadingProp) {
    return null;
  }

  return (
    <BuyItAgainCarousel
      anchorId={anchorId}
      itemClass="fixed-item"
      title="Buy It Again"
      scheme={schemaName}
      storeId={storeId}
      membershipInformation={membershipInformation}
      zipCode={zipCode}
      analyticsAnchorProductSku={anchorIdForAnalytics}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      data={searchModelData}
      loading={isLoading(searchModelData?.buyitagain, loader)}
      showLoading={false}
    />
  );
};

CoreBuyItAgain.displayName = 'BuyItAgain';
CoreBuyItAgain.dataModel = extend({}, dataModel, BuyItAgainCarousel);
CoreBuyItAgain.propTypes = {
  anchorId: stringType.isRequired,
  schemaName: stringType.isRequired,
  searchStoreId: stringType,
  keyword: stringType,
  // eslint-disable-next-line react/forbid-prop-types
  searchModelData: object,
  nValue: stringType,
  data: shape({}),
  loading: bool,
  zipCode: stringType
};

CoreBuyItAgain.defaultProps = {
  searchStoreId: '',
  keyword: '',
  nValue: '',
  searchModelData: null,
  data: null,
  loading: false,
  zipCode: undefined
};

export { CoreBuyItAgain };
