export const track = (key) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.top.LIFE_CYCLE_EVENT_BUS.trigger(`fit-compatibility-drawer.${key}`);
  }
};

const fridgeValidator = (str, validation) => str?.toLowerCase().includes(validation);

export const getIsRefrigeratorPage = (data) => {
  const {
    taxonomy = {},
    metadata = {},
    identifiers = {}
  } = data?.searchModel || data?.product || {};
  const { breadCrumbs = [] } = taxonomy;
  const {
    canonicalUrl = '',
    clearAllRefinementsURL = '',
    h1Tag = '',
  } = metadata;
  const {
    productLabel = ''
  } = identifiers;

  return ['refrigerator', 'fridge'].some((validation) => {
    return breadCrumbs?.some(({ label }) => fridgeValidator(label, validation))
      || fridgeValidator(canonicalUrl, validation)
      || fridgeValidator(clearAllRefinementsURL, validation)
      || fridgeValidator(h1Tag, validation)
      || fridgeValidator(productLabel, validation);
  });
};
