export const ADD_AREA = 'add area';
export const DELETE_ALL_INPUTS_CONFIRMATION = 'delete all inputs confirmation';
export const PLAN_YOUR_PROJECT = 'Plan your project';
export const LANDING = 'landing';
export const VINYL_SHEET_POPOVER_MESSAGE = 'Most vinyl sheet rolls come in a standard 12-foot width which is enough'
+ ' to cover a room that is 11.5 ft. wide or smaller. Rooms wider than 11.5 ft. will require a seam so you may want '
+ 'to call a professional installer.';
export const POD_IMAGE_SIZE = '150';
export const TOTAL_ESTIMATED_BELOW = 'Total Square Footage Estimated Below';

export const FLOORING_TYPES = [
  {
    answer: 'Hardwood Flooring',
    refinementKey: '5yc1vZaq8x',
    url: '/b/Flooring-Hardwood-Flooring/N-5yc1vZaq8x',
    pictureURL: 'https://dam.thdstatic.com/content/production/yooL03M-C__tx0DiO3i6EA/'
    + 'mu2uXicfIN-Hv0Vh_xsDLQ/Original%20file/29bfeed6-b594-45ad-86af-715d4fabce05_327491453_r2_LoRes.jpg',
    description: 'Strong, stylish, and perfect for almost any room'
  },
  {
    answer: 'Tile Flooring',
    refinementKey: '5yc1vZar0y',
    url: '/b/Flooring-Tile/N-5yc1vZar0y',
    pictureURL: 'https://dam.thdstatic.com/content/production/_M70p1db2doRFZIrbi8itg/l3B18aksPxazS'
    + 'ZGrymkozw/Original%20file/b1f7f61f-e71a-4e72-b306-4a1a8860541b_FPE18RUZ_332_LM.jpeg',
    description: 'Durable, low-maintenance option available in a wide variety of materials'
  },
  {
    answer: 'Vinyl Flooring',
    refinementKey: '5yc1vZapwr',
    url: '/b/Flooring-Vinyl-Flooring/N-5yc1vZapwr',
    pictureURL: 'https://dam.thdstatic.com/content/production/NGtqA5QOEPP_rDsAKiwra'
    + 'Q/uekGR3XMiuyVNQtp4jRUig/optimizedFile/TrafficMaster-Edwards-Oak_Kitchen-Hero.jpg',
    description: 'Provides an easy-to-install, waterproof, durable, stylish and affordable option. '
  },
  {
    answer: 'Laminate Flooring',
    refinementKey: '5yc1vZare1',
    url: '/b/Flooring-Laminate-Flooring/N-5yc1vZare1',
    pictureURL: 'https://dam.thdstatic.com/content/production/TrPj9crkJFlpn0azF5TRng/WLfrAsmnBpRXoy'
    + '-tw2uqag/Original%20file/7307b1f5-768f-4c33-aaba-3feb192f99c2_314537739-LF001012-PergoOutlast'
    + '_-SandyCoveOak-Hover.jpeg',
    description: 'Low-maintenance affordability with the look of real wood,'
    + ' tile or stone and a wood-based core for added strength.'
  },
];