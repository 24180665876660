/* eslint-disable max-len */
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  string, shape, arrayOf, func
} from 'prop-types';
import { FitCompatibility } from '@thd-olt-component-react/fit-compatibility';
import {
  DrawerHeader,
  DrawerBody,
  Typography
} from '@one-thd/sui-atomic-components';
import {
  getRefinementsByDimension,
  isRefinementActive,
  sortSelectedDimensions,
  sortRefinementKeys,
  sortAscending,
  findCustomPriceRange,
  getDimensionsForUrl,
  getRefinemntsLabelsForUrl,
  filtersCatStyleCheck,
  sortOrder,
  onRatingRefinements,
  storeLifecycleEvent
} from '../../product-results-helpers';
import { MultiStateDrawerRefinement } from './MultiStateDrawerRefinement';
import { PriceRangeRefinement } from '../Refinements/PriceRangeRefinement';
import { RatingRefinement } from './RatingRefinement';
import { CustomDrawerFooter } from './CustomDrawerFooter';
import { ProductResultsContext } from '../ProductResultsContext';

const PrimaryFilterDrawerDimension = ({
  dimension,
  appliedDimensions,
  onCancel,
  onDimensionChange,
  baseUrl,
  canonicalUrl,
  onRefinementsUpdate
}) => {

  const { data } = useContext(ProductResultsContext);
  const resultsAppliedDimensions = data?.searchModel?.appliedDimensions;

  const footerLabel = resultsAppliedDimensions.filter((item) => item.label === dimension.label).length > 0;

  const activeSelectedRefinements = appliedDimensions ? getRefinementsByDimension(appliedDimensions, dimension) : [];
  let [selectedRefinements, setSelectedRefinements] = useState(activeSelectedRefinements);

  const [revision, setRevision] = useState(0);
  const [customRangeEntered, setCustomRangeEntered] = useState(0);

  const [lowerBound, setLowerBound] = useState('');
  const [upperBound, setUpperBound] = useState('');

  const isLowerBoundEntered = (value) => setLowerBound(value);

  const isUpperBoundEntered = (value) => setUpperBound(value);

  const desktopSort = useRef('');

  const handleSortOrderChange = ({ output: result }) => {
    if (result?.sortby && result?.sortorder) {
      desktopSort.current = `${result.sortby}:${result.sortorder}`;
    }
  };

  useEffect(() => {
    setSelectedRefinements(getRefinementsByDimension(appliedDimensions, dimension));
    window.LIFE_CYCLE_EVENT_BUS.on('sortorder.value', handleSortOrderChange);
  }, [appliedDimensions, desktopSort]);

  const onRefinementChange = ({ refinement }) => {
    // verify whether or not the refinement is on the list
    let index = refinement?.dimension?.dimensionId === 'custom_price'
      ? -1 : selectedRefinements.findIndex((ref) => ref.refinementKey === refinement.refinementKey);

    // coppy current list
    let temp = Array.isArray(selectedRefinements) ? selectedRefinements.slice() : [];
    if (index >= 0) {
      // if already in the list, remove it
      temp.splice(index, 1);
    } else {
      // if not in the list, add it
      temp.push(refinement);
    }
    setSelectedRefinements(temp);
  };

  const handleStarRatingChange = ({ refinement }) => {
    setSelectedRefinements(onRatingRefinements(refinement));
  };

  const clearInputFields = () => {
    setLowerBound('');
    setUpperBound('');
    setRevision(revision + 1);
  };

  const processAppliedDimensions = (currentDimension, currentRefinements, existingDimensions) => {
    const updatedTemp = existingDimensions.slice();
    const index = existingDimensions.findIndex((ref) => ref.label === currentDimension.label);

    if (index !== -1) {
      updatedTemp[index] = { ...updatedTemp[index], refinements: [...currentRefinements] };
    } else {
      updatedTemp.push({ label: currentDimension.label, refinements: selectedRefinements });
    }

    return updatedTemp;
  };

  const handleOnApply = () => {
    document.body.classList.add('filter-and-sort--fade');
    setTimeout(() => {
      const selectedimensions = processAppliedDimensions(dimension, selectedRefinements, resultsAppliedDimensions);
      const sortedSelectedimensions = sortSelectedDimensions(selectedimensions);

      // get refinement keys and sort them by id
      let refinementKeys = sortedSelectedimensions
        .map((dim) => dim.refinements.map((ref) => ref.refinementKey)).flat(1);

      let sortedRefinementKeys = sortRefinementKeys(refinementKeys);

      if (selectedimensions && Object.keys(selectedimensions).length > 0) {
        storeLifecycleEvent('product-results.change-filters-refinements', selectedimensions);
      }
      // get dimensions that may go on the url
      let dimensionsIncludedInUrl = getDimensionsForUrl(sortedSelectedimensions);

      // get refinements from the dimensions which are added to the url
      let refinementsIncludedInUrl = dimensionsIncludedInUrl?.map(
        (_dimension) => _dimension.refinements.sort(sortAscending)).flat(1);
      let length = refinementsIncludedInUrl.length;
      let lastRefinement = refinementsIncludedInUrl[length - 1];
      let urlParts = baseUrl?.split('?')[0].split('/');

      // get custom price range
      const priceDimension = selectedimensions.find((_dimension) => _dimension.label === 'Price');
      const [lowerbound, upperbound] = findCustomPriceRange(priceDimension?.refinements);
      const canonicalUrlParts = canonicalUrl.split('?');
      let queryParams = canonicalUrlParts.length > 1 ? canonicalUrlParts[1] : '';
      if (lowerbound || upperbound) {
        let queries = queryParams.split('&')
          .filter((query) => !query.toLowerCase().includes('lowerbound') && !query.toLowerCase().includes('upperbound'));
        queryParams = queries.join('&');
        queryParams += `&lowerbound=${lowerbound}&upperbound=${upperbound}`;
      }

      queryParams = filtersCatStyleCheck(queryParams);

      queryParams = sortOrder({ desktopSort, queryParams });

      const nValue = urlParts.find((part) => part.indexOf('N-') !== -1) || '';
      let refinementsIds = nValue;
      if (sortedRefinementKeys.length > 0) {
        refinementsIds += 'Z' + sortedRefinementKeys.join('Z');
      }

      // get refinement labels for url
      const refinamentsLabelsForUrl = getRefinemntsLabelsForUrl(refinementsIncludedInUrl);
      urlParts.splice(urlParts.indexOf(nValue), 0, ...refinamentsLabelsForUrl);

      urlParts.splice(urlParts.indexOf(nValue), 1, refinementsIds);
      const url = queryParams ? urlParts.join('/') + '?' + queryParams : urlParts.join('/');
      lastRefinement = { ...lastRefinement, url };
      onDimensionChange({ refinement: lastRefinement });
      onCancel(selectedRefinements, 'apply');
      clearInputFields();
      document.body.classList.remove('filter-and-sort--fade');
    }, 0);
  };

  const hasFitCompatibility = [
    'DEPTH (EXCLUDING HANDLES) (IN.)',
    'HEIGHT TO TOP OF DOOR HINGE (IN.)',
    'HEIGHT TO TOP OF REFRIGERATOR (IN.)',
    'INSTALLATION DEPTH',
    'REFRIGERATOR FIT WIDTH',
    'TOTAL CAPACITY (CU. FT.)',
    'CAPACITY (CU. FT.) - REFRIGERATORS',
    'REFRIGERATOR CAPACITY (CU. FT.)',
    'DEPTH (INCLUDING HANDLES) (IN.)'
  ].includes(dimension.label.toUpperCase());

  const customRangeHeading = (text) => (
    <div className="sui-mt-1 sui-mb-3">
      <Typography align="left">{text}</Typography>
    </div>
  );

  const triggerRefinementForCustomRange = () => {
    onRefinementChange({
      refinement: {
        lowerbound: lowerBound,
        upperbound: upperBound,
        urlSearchParams: window.location.search,
        label: `$${lowerBound} - $${upperBound}`,
        deselectUrl: '?',
        refinementKey: null,
        dimension: {
          dimensionId: 'custom_price',
          label: 'Price'
        }
      }
    });
  };

  // add entered custom range into refinements object
  useEffect(() => {
    if (lowerBound.length !== 0 || upperBound.length !== 0) {
      setCustomRangeEntered(1);
      triggerRefinementForCustomRange();
    } else if (lowerBound.length === 0 || upperBound.length === 0) {
      setCustomRangeEntered(0);
      setSelectedRefinements(activeSelectedRefinements);
    }

  }, [lowerBound, upperBound]);

  useEffect(() => {
    onRefinementsUpdate(selectedRefinements);
  }, [selectedRefinements]);

  return (
    <>
      <DrawerHeader data-testid={`drawer-header-${dimension.label}`} title={dimension.label} onClose={() => onCancel(selectedRefinements, 'close')} />
      <DrawerBody data-testid="drawer-body">
        {
          dimension.label === 'Price' && (
            <>
              {customRangeHeading('Enter a custom range:')}
              <PriceRangeRefinement
                dimension={dimension}
                onChange={onRefinementChange}
                isLowerBoundEntered={isLowerBoundEntered}
                isUpperBoundEntered={isUpperBoundEntered}
                revision={revision}
                isPrimaryFilter
              />
              {customRangeHeading('Select a range:')}
            </>
          )
        }
        {
          dimension.label === 'Review Rating' && (
            <RatingRefinement
              key={`primary-filters-rerfinement-${dimension.label}`}
              dimension={dimension}
              onItemSelection={handleStarRatingChange}
              selectedRefinements={selectedRefinements}
            />
          )
        }
        <div>
          { hasFitCompatibility && (
            <div
              role="button"
              tabIndex={0}
              aria-label="fit-compatibility"
              className="sui-p-1 sui-pb-0 sui-ml-1"
              onClick={() => onCancel(selectedRefinements, 'close')}
            >
              <FitCompatibility
                type="MINI"
                drawerPosition="left"
                imageCacheEnabled
              />
            </div>
          )}
          {dimension.refinements.map((refinement) => {
            if (dimension.label !== 'Review Rating') {
              return (
                <MultiStateDrawerRefinement
                  refinement={refinement}
                  key={`primary-filters-refinement-${refinement.refinementKey}`}
                  onItemSelection={onRefinementChange}
                  isRefinementSelected={isRefinementActive(refinement.refinementKey, selectedRefinements)}
                />
              );
            }
            return null;
          })}
        </div>
      </DrawerBody>
      <CustomDrawerFooter
        customRangeEntered={customRangeEntered}
        selectedRefinements={selectedRefinements}
        activeSelectedRefinements={activeSelectedRefinements}
        onApply={handleOnApply}
        onCancel={onCancel}
        footerLabel={footerLabel}
      />
    </>
  );
};

PrimaryFilterDrawerDimension.displayName = 'PrimaryFilterDrawerDimension';
PrimaryFilterDrawerDimension.propTypes = {
  dimension: shape({
    label: string
  }).isRequired,
  appliedDimensions: arrayOf(shape({})).isRequired,
  baseUrl: string.isRequired,
  canonicalUrl: string.isRequired,
  onCancel: func.isRequired,
  onDimensionChange: func.isRequired,
  onRefinementsUpdate: func.isRequired
};

export default PrimaryFilterDrawerDimension;
