import React from 'react';
import { RichText } from '@thd-olt-component-react/rich-text';
import PropTypes, { string, bool, shape } from 'prop-types';

export const RichTextWrapper = ({
  textWidthClasses,
  textPaddingClasses,
  componentId,
  richText,
  isLivePreview
}) => {

  return (
    <div
      data-testid="text-padding-test"
      className={`${textWidthClasses} 
          ${textPaddingClasses}`}
      data-contentful-entry-id={componentId}
      data-contentful-field-id="description"
    >
      <RichText content={richText} isLivePreview={isLivePreview} />
    </div>
  );
};

RichTextWrapper.propTypes = {
  componentId: string.isRequired,
  isLivePreview: bool.isRequired,
  richText: PropTypes.oneOfType([
    string,
    PropTypes.object
  ]).isRequired,
  textWidthClasses: string.isRequired,
  textPaddingClasses: string.isRequired,
};
