import React from 'react';
import {
  func, string, arrayOf, shape, bool
} from 'prop-types';
import { Button, Typography } from '@one-thd/sui-atomic-components';
import { Filters, ArrowDown } from '@one-thd/sui-icons';
import { PrimaryFiltersDrawer } from './PrimaryFiltersDrawer';

const PrimaryFilters = ({
  dimensions,
  appliedDimensions,
  onRefinementChange,
  onCloseDrawer,
  baseUrl,
  canonicalUrl,
  primaryFilters,
  hideViewAll,
  isOpen,
  activeDimension,
  onSecondaryFilterDrawerToggle
}) => {

  if (!dimensions) {
    return null;
  }
  if (dimensions.length === 0) {
    return null;
  }
  const renderPrimaryFiltersButton = (dimension) => {
    return (
      <div
        key={dimension.dimensionId}
        data-testid="primary-filters-btn"
        aria-label={dimension.label}
      >
        <Button
          variant="ghost"
          onClick={() => onCloseDrawer(true, dimension)}
        >
          <div className="sui-pr-2 sui-text-nowrap">
            <Typography variant="body-base" color="sui-bg-primary">{dimension.label}</Typography>
          </div>
          <ArrowDown size="small" />
        </Button>
      </div>
    );
  };

  const renderFilterButtons = (primaryFiltersDimensions) => {
    if (primaryFiltersDimensions.length === 0) {
      return null;
    }
    return (
      <div className="sui-flex sui-mx-2">
        {!hideViewAll && (
          <div>
            <Button
              data-testid="all-filters-button"
              variant="ghost"
              onClick={() => onSecondaryFilterDrawerToggle(true)}
            >
              <Filters />
              <div className="sui-pl-2 sui-text-nowrap">
                <Typography variant="body-base" weight="bold" color="sui-bg-primary">All Filters</Typography>
              </div>
            </Button>
          </div>
        )}
        {
          primaryFiltersDimensions.map((dimension) => (
            renderPrimaryFiltersButton(dimension)
          ))
        }
      </div>
    );
  };

  return (
    <div className="primary-filters" data-component="PrimaryFilterDimensions">

      {renderFilterButtons(primaryFilters)}

      <PrimaryFiltersDrawer
        isOpen={isOpen}
        activeDimension={activeDimension}
        onCloseDrawer={onCloseDrawer}
        dimensions={dimensions}
        appliedDimensions={appliedDimensions}
        onDimensionChange={onRefinementChange}
        canonicalUrl={canonicalUrl}
        baseUrl={baseUrl}
      />
    </div>
  );
};

PrimaryFilters.propTypes = {
  baseUrl: string.isRequired,
  canonicalUrl: string.isRequired,
  dimensions: arrayOf(
    shape({
      label: string.isRequired
    })
  ).isRequired,
  primaryFilters: arrayOf(
    shape({
      label: string.isRequired
    })
  ).isRequired,
  appliedDimensions: arrayOf(shape({})).isRequired,
  activeDimension: arrayOf(shape({})).isRequired,
  onRefinementChange: func.isRequired,
  onCloseDrawer: func.isRequired,
  onSecondaryFilterDrawerToggle: func.isRequired,
  hideViewAll: bool,
  isOpen: bool.isRequired

};

PrimaryFilters.defaultProps = {
  hideViewAll: false
};

PrimaryFilters.displayName = 'PrimaryFilters';

export { PrimaryFilters };
