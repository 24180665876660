import React from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  PodSection,
  PodSpacer,
  ProductPod,
  ProductImage,
  ProductIdentifier,
  ProductATC,
  PodFooter
} from '@thd-olt-component-react/product-pod';
import {
  bool as boolType,
  string as stringType,
  number as numType
} from 'prop-types';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';

export const BuyItAgainProductPod = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    itemId,
    storeId,
    position,
    parent,
    zoneName,
    zonePosition,
    podResults,
    scheme,
    anchorProduct,
    preferredPriceFlag,
    brandTitleMaxLine,
    hideATC
  } = props;

  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      padding="sui-p-2"
      analyticsData={{
        parent, position, scheme, anchorProduct, zoneName, zonePosition, podResults
      }}
      render={(pod) => (
        <>
          <PodSection noPadding>
            <div className="sui-grid sui-gap-2 sui-grid-cols-12">
              <div className="sui-col-span-5">
                <ProductImage
                  itemId={pod.itemId}
                  showSecondaryImage={pod.showSecondaryImage}
                  onClick={() => { }}
                />
              </div>
              <div className="sui-col-span-6">
                <PodSection columnAlign>
                  <PodSpacer minHeight="72px">
                    <ProductHeader
                      brand="inline"
                      itemId={pod.itemId}
                      brandTitleMaxLine={brandTitleMaxLine}
                      disableShopThisCollection
                      productNameLineHeight="tight"
                    />
                  </PodSpacer>
                  <Price
                    itemId={pod.itemId}
                    large={false}
                    storeId={storeId}
                    displayEachUom={false}
                    hideSavingsText={!preferredPriceFlag}
                    showPreferredPricingBadge
                  />
                  {!hideATC
            && (
              <PodFooter noPadding>
                <PodSpacer minHeight="40px">
                  <ProductATC
                    itemId={pod.itemId}
                    storeId={storeId}
                    checkGeneric
                    outline
                  />
                </PodSpacer>
              </PodFooter>
            )}
                </PodSection>
              </div>
            </div>
          </PodSection>

        </>
      )}
    />
  );
};

BuyItAgainProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductIdentifier,
  ProductATC,
  Price,
  ProductHeader
);

BuyItAgainProductPod.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  storeId: stringType,
  position: numType,
  parent: stringType,
  zoneName: stringType,
  zonePosition: stringType,
  podResults: numType,
  scheme: stringType,
  anchorProduct: stringType,
  preferredPriceFlag: boolType,
  brandTitleMaxLine: numType,
  hideATC: boolType
};

BuyItAgainProductPod.defaultProps = {
  storeId: '',
  position: '',
  parent: '',
  zoneName: '',
  zonePosition: 'n/a',
  podResults: 0,
  scheme: 'drecs',
  anchorProduct: '',
  preferredPriceFlag: false,
  brandTitleMaxLine: 3,
  hideATC: false
};
BuyItAgainProductPod.displayName = 'BuyItAgainProductPod';
