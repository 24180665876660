// fallback logic:
// - desktop image is always required
// - if there is desktop image, tablet image, and mobile image, each breakpoint has unique image
// - if there is desktop image and tablet image, mobile will use tablet image
// - if there is desktop image and mobile image, tablet will use desktop image
// - if there is only desktop image, all 3 breakpoints will use desktop

// Refactored with love and tears by Daniel and Max (but mostly Patricia)

export const getOrientationType = (damImageAlignmentBehavior = '', descriptionAlignment = '', richText = '') => {
  return {
    isCentered: damImageAlignmentBehavior === 'Centered',
    isVertical: descriptionAlignment === 'Below image' || descriptionAlignment === 'Above image' || !richText,
    imageFirst: descriptionAlignment === 'Below image' || descriptionAlignment === 'To the right of the image'
  };
};

export const getImageAndTextWidthClasses = (damImageWidthBehavior = '', isVertical = false) => {
  switch (damImageWidthBehavior) {
  case 'Skinny':
    return {
      imageWidthClasses: `sui-col-span-12 ${isVertical
        ? 'sm:sui-col-span-8 md:sui-col-span-6' : 'sm:sui-col-span-6 md:sui-col-span-4'}`,
      textWidthClasses: `sui-col-span-12 ${isVertical ? '' : 'sm:sui-col-span-6 md:sui-col-span-8'}`
    };
  case 'Wide':
    return {
      imageWidthClasses: `sui-col-span-12 ${isVertical ? '' : 'sm:sui-col-span-6 lg:sui-col-span-8'}`,
      textWidthClasses: `sui-col-span-12 ${isVertical ? '' : 'sm:sui-col-span-6 lg:sui-col-span-4'}`
    };
  default:
    return {
      imageWidthClasses: `sui-col-span-12 ${isVertical ? 'sm:sui-col-span-8' : 'sm:sui-col-span-6'}`,
      textWidthClasses: `sui-col-span-12 ${isVertical ? '' : 'sm:sui-col-span-6'}`
    };
  }
};

export const getTextPaddingClasses = (descriptionAlignment = '') => {
  switch (descriptionAlignment) {
  case 'Below image':
    return 'sui-pt-4 md:sui-pt-6 xl:sui-pt-8';
  case 'Above image':
    return 'sui-pb-4 md:sui-pb-6 xl:sui-pb-8';
  case 'To the right of the image':
    return 'sui-pt-4 sm:sui-pt-0 sm:sui-pl-4 md:sui-pl-6 xl:sui-pl-8';
  case 'To the left of the image':
    return 'sui-pb-4 sm:sui-pb-0 sm:sui-pr-4 md:sui-pr-6 xl:sui-pr-8';
  default:
    return '';
  }
};

export const getDisplayClasses = (tabletImage = '', mobileImage = '') => {
  if (tabletImage && mobileImage) {
    return {
      desktopDisplay: 'sui-hidden xl:sui-flex xl:sui-grid',
      tabletDisplay: 'sui-hidden md:sui-flex md:sui-grid xl:sui-hidden',
      mobileDisplay: 'sui-flex sui-grid md:sui-hidden'
    };
  }
  if (tabletImage) {
    return {
      desktopDisplay: 'sui-hidden xl:sui-flex xl:sui-grid',
      tabletDisplay: 'sui-flex sui-grid xl:sui-hidden',
      mobileDisplay: null
    };
  }
  if (mobileImage) {
    return {
      desktopDisplay: 'sui-hidden md:sui-flex md:sui-grid',
      tabletDisplay: null,
      mobileDisplay: 'sui-flex sui-grid md:sui-hidden'
    };
  }
  return {
    desktopDisplay: 'sui-flex sui-grid',
    tabletDisplay: null,
    mobileDisplay: null
  };
};

const getLeftSpaceClassesForBreakpoint = (isCentered, extraClasses) => {
  return isCentered ? `sui-col-start-0 ${extraClasses}` : 'sui-col-start-0';
};

export const getImageUrl = (breakpoint, dataComponent) => {
  const imageData = dataComponent?.[`${breakpoint}Image`];
  const downloadedImage = imageData?.damDownloadedContent?.url;
  const selectedImage = imageData?.damContentSelector?.assetData?.[0]?.selectedImageUrl;
  const url = selectedImage || downloadedImage;
  if (!url) {
    return null;
  }
  return url;
};

const getSkinnyLeftSpaceClasses = ({
  isCentered = false,
  mobileDisplay,
  tabletDisplay,
}) => {
  let leftSpaceClassesDesk;
  let leftSpaceClassesTab = 'sui-col-start-0';
  let leftSpaceClassesMob = 'sui-col-start-0';
  if (mobileDisplay !== null && tabletDisplay !== null) {
    // desktop, tablet, mobile
    leftSpaceClassesDesk = getLeftSpaceClassesForBreakpoint(isCentered, 'xl:sui-col-start-4');
    leftSpaceClassesTab = getLeftSpaceClassesForBreakpoint(isCentered, 'md:sui-col-start-4 xl:sui-col-start-0');
    leftSpaceClassesMob = getLeftSpaceClassesForBreakpoint(isCentered, 'sm:sui-col-start-3 md:sui-col-start-0');
  } else if (tabletDisplay !== null) {
    // desktop & tablet
    leftSpaceClassesDesk = getLeftSpaceClassesForBreakpoint(isCentered, 'xl:sui-col-start-4');
    leftSpaceClassesTab = getLeftSpaceClassesForBreakpoint(
      isCentered,
      'sm:sui-col-start-3 md:sui-col-start-4 xl:sui-col-start-0'
    );
  } else if (mobileDisplay !== null) {
    // desktop & mobile
    leftSpaceClassesDesk = getLeftSpaceClassesForBreakpoint(isCentered, 'md:sui-col-start-4');
    leftSpaceClassesMob = getLeftSpaceClassesForBreakpoint(isCentered, 'sm:sui-col-start-3 md:sui-col-start-0');
  } else {
    // desktop only
    leftSpaceClassesDesk = getLeftSpaceClassesForBreakpoint(isCentered, 'sm:sui-col-start-3 md:sui-col-start-4');
  }
  return {
    leftSpaceClassesDesk,
    leftSpaceClassesTab,
    leftSpaceClassesMob
  };
};

const getNormalLeftSpaceClasses = ({
  isCentered = false,
  mobileDisplay,
  tabletDisplay,
}) => {
  let leftSpaceClassesDesk;
  let leftSpaceClassesTab = 'sui-col-start-0';
  let leftSpaceClassesMob = 'sui-col-start-0';
  if (mobileDisplay !== null && tabletDisplay !== null) {
    // desktop, tablet, mobile
    leftSpaceClassesDesk = getLeftSpaceClassesForBreakpoint(isCentered, 'xl:sui-col-start-3');
    leftSpaceClassesTab = getLeftSpaceClassesForBreakpoint(isCentered, 'md:sui-col-start-3 xl:sui-col-start-0');
    leftSpaceClassesMob = getLeftSpaceClassesForBreakpoint(isCentered, 'sm:sui-col-start-3 md:sui-col-start-0');
  } else if (tabletDisplay !== null) {
    // desktop & tablet
    leftSpaceClassesDesk = getLeftSpaceClassesForBreakpoint(isCentered, 'xl:sui-col-start-3');
    leftSpaceClassesTab = getLeftSpaceClassesForBreakpoint(isCentered, 'sm:sui-col-start-3 xl:sui-col-start-0');
  } else if (mobileDisplay !== null) {
    // desktop & mobile
    leftSpaceClassesDesk = getLeftSpaceClassesForBreakpoint(isCentered, 'md:sui-col-start-3');
    leftSpaceClassesMob = getLeftSpaceClassesForBreakpoint(isCentered, 'sm:sui-col-start-3 md:sui-col-start-0');
  } else {
    // desktop only
    leftSpaceClassesDesk = getLeftSpaceClassesForBreakpoint(isCentered, 'sm:sui-col-start-3 md:sui-col-start-3');
  }
  return {
    leftSpaceClassesDesk,
    leftSpaceClassesTab,
    leftSpaceClassesMob
  };
};

export const getLeftSpaceClasses = ({
  isVertical = false,
  isCentered = false,
  mobileDisplay,
  tabletDisplay,
  damImageWidthBehavior = '',
}) => {
  const leftSpaceClassesDesk = 'sui-col-start-0';
  const leftSpaceClassesTab = 'sui-col-start-0';
  const leftSpaceClassesMob = 'sui-col-start-0';

  if (!isVertical) {
    return {
      leftSpaceClassesDesk,
      leftSpaceClassesTab,
      leftSpaceClassesMob
    };
  }

  if (damImageWidthBehavior === 'Skinny') {
    return getSkinnyLeftSpaceClasses({
      isCentered,
      mobileDisplay,
      tabletDisplay
    });
  }
  if (damImageWidthBehavior === 'Normal') {
    return getNormalLeftSpaceClasses({
      isCentered,
      mobileDisplay,
      tabletDisplay
    });
  }

  return {
    leftSpaceClassesDesk,
    leftSpaceClassesTab,
    leftSpaceClassesMob
  };
};