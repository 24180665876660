/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { TileGroup, Typography } from '@one-thd/sui-atomic-components';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { isRefinementActive } from '../../product-results-helpers';
import { AvailabilityRefinement } from '../Refinements/AvailabilityRefinement';
import { StoreSelector } from '../StoreSelector/StoreSelector';
import '../../styles/availability-filters.scss';

const AvailabilityDimension = ({
  dimension,
  drawer,
  onChange,
  multiFacet,
  selectedRefinements,
  onDisplayData,
  onMultiStoreSelectionChange,
  multiStoreSelection,
  enableMultiStore,
  isMobile,
  isGetItFastOnPlp
}) => {

  const [collapse, setCollapse] = useState(!!drawer);
  const [multiStoreIds, setMultiStoreIds] = useState(multiStoreSelection ? [multiStoreSelection] : []);
  const { storeName } = useStore({ varnish: false });
  const { deliveryZip } = useContext(ExperienceContext);
  const { dimensionId, label, refinements } = dimension;
  const showDeliveryOptions = { pickupToday: false, deliveryLocation: false };
  refinements.forEach((ref) => {
    // eslint-disable-next-line max-len
    if (ref.refinementKey === '1z175a5' && ref.selected) showDeliveryOptions.pickupToday = true;
    else if (ref.refinementKey === 'bwo6i' && ref.selected) showDeliveryOptions.deliveryLocation = true;
    else if (ref.refinementKey === '1z175cq' && ref.selected) showDeliveryOptions.deliveryLocation = true;
  });
  const { pickupToday, deliveryLocation } = showDeliveryOptions;

  const getRefinementId = (refinement, refinementIndex) => {
    const { refinementKey } = refinement;
    return `${refinementKey}-${refinementIndex}`;
  };

  const multiStoreCallback = ({ stores }) => {
    const checkedMultiStoreIds = stores.filter((store) => store.checked)
      .map((checkedStore) => parseInt(checkedStore.storeId, 10));

    // selected returning null after first store is selcted in dropdown
    // we are adding additional check to see if there are unchecked stores in dropdown menu
    if ((pickupToday || checkedMultiStoreIds.length < 5) && enableMultiStore && onMultiStoreSelectionChange) {
      onMultiStoreSelectionChange({ multiStoreIds: checkedMultiStoreIds, onDisplay: onDisplayData?.isStoreDisplay });
    }
    setMultiStoreIds(checkedMultiStoreIds);
  };

  const dimensionClassName = classNames('dimension__availability', {
    'dimension__availability--desktop': !isMobile,
    'dimension__availability--mini': isMobile,
  });

  const wrapperClassName = classNames({
    'dimension--collapsed': collapse
  });

  const groupClassName = classNames('dimension__group sui-flex-nowrap', {
    'dimension__group--mini': isMobile,
    'dimension__group--scroll': !isMobile,
    'dimension__group--drawer-scroll': drawer
  });

  const shadowClassName = classNames({ 'dimension__box--shadow': drawer });

  const handleDelivery = () => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('open_delivery_zip_menu');
  };

  const isApplianceBundleRef = useRef(
    typeof (window) !== 'undefined' && window?.location?.href?.includes('N-5yc1vZ2fkpfuj')
  );

  return (
    <>
      <div className={dimensionClassName}>
        <Row className={shadowClassName}>
          <Row className={wrapperClassName}>
            <div
              className={groupClassName}
              key={dimensionId}
              data-instoredisplay="true"
              data-testid="availability-refinements"
            >
              <TileGroup
                onChange={onChange}
              >
                {
                  refinements && refinements
                    .map((refinement, refinementIndex) => {
                      // todo: this might be a dead dependency. Look at removing it in the future.
                      const active = isRefinementActive(refinement.refinementKey, selectedRefinements);
                      const id = getRefinementId(refinement, refinementIndex);
                      if (
                        isApplianceBundleRef.current
                        && (refinement.label === 'Same-Day Delivery' || refinement.label === 'Next-Day Delivery')
                      ) {
                        return null;
                      }
                      return (
                        <AvailabilityRefinement
                          dimension={dimension}
                          id={id}
                          key={id}
                          enableMultiStore={enableMultiStore}
                          refinement={refinement}
                          onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                          onChange={onChange}
                          multiStoreSelection={multiStoreSelection}
                          setMultiStoreIds={setMultiStoreIds}
                          onDisplayData={onDisplayData}
                          active={active}
                          isMobile={isMobile}
                          isGetItFastOnPlp={isGetItFastOnPlp}
                        />
                      );
                    })
                }
              </TileGroup>
            </div>
          </Row>
        </Row>
      </div>
      {(pickupToday || deliveryLocation) && (
        <div className="pickupOrDelivery-Wrapper">
          {pickupToday && (
            <div className="refinement__availability--pickupOrDelivery">
              <Row>
                <Col xs="10" fallback="10" className="store-selector--wrapper">
                  <StoreSelector
                    multiStoreCallback={multiStoreCallback}
                    multiStoreSelection={multiStoreSelection}
                    multiFacet={multiFacet}
                    storeName={storeName}
                    isGetItFastOnPlp={isGetItFastOnPlp}
                    refinements={refinements}
                  />
                </Col>
              </Row>
            </div>
          )}
          {deliveryLocation && (
            <div className="refinement__availability--pickupOrDelivery">
              <Row>
                <Col xs="10" fallback="10" className="store-selector--wrapper">
                  <span className="store-selector__preamble"> Delivery to </span>
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <span onClick={handleDelivery} className="store-selector__link">{deliveryZip || '...' }</span>
                </Col>
              </Row>
            </div>
          )}
        </div>
      )}
    </>
  );
};

AvailabilityDimension.propTypes = {
  dimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    )
  }).isRequired,
  drawer: PropTypes.bool,
  enableMultiStore: PropTypes.bool,
  multiFacet: PropTypes.bool,
  multiStoreSelection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDisplayData: PropTypes.shape({
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func,
    isStoreDisplay: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    itemCount: PropTypes.string
  }),
  onMultiStoreSelectionChange: PropTypes.func,
  selectedRefinements: PropTypes.arrayOf(PropTypes.shape({})),
  isMobile: PropTypes.bool,
  isGetItFastOnPlp: PropTypes.bool
};

AvailabilityDimension.defaultProps = {
  drawer: false,
  enableMultiStore: PropTypes.bool,
  multiFacet: false,
  multiStoreSelection: PropTypes.string,
  onDisplayData: {
    onDisplay: 'false',
    onDisplayChange: null,
    isStoreDisplay: 'false',
    itemCount: null
  },
  onMultiStoreSelectionChange: PropTypes.func,
  selectedRefinements: null,
  isMobile: false,
  isGetItFastOnPlp: false
};

AvailabilityDimension.displayName = 'AvailabilityDimension';

export { AvailabilityDimension };
