import React, { useContext } from 'react';
import PropTypes, { func } from 'prop-types';
import { DrawerFooter, Button } from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';

const CancelButton = ({ onClick, selectedRefinements }) => {
  return (
    <Button fullWidth variant="secondary" onClick={() => onClick(selectedRefinements, 'close')}>
      Cancel
    </Button>
  );
};

const ApplyButton = ({ onClick, disabled, label }) => (
  <Button
    fullWidth
    onClick={onClick}
    disabled={disabled}
  >
    {label}
  </Button>
);

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedRefinements: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

ApplyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};

export const CustomDrawerFooter = ({
  onCancel,
  customRangeEntered = 0,
  selectedRefinements = [],
  activeSelectedRefinements = [],
  onApply,
  footerLabel
}) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const buttonLabel = footerLabel ? 'Update' : 'Apply';

  const isDisabled = customRangeEntered === 0
    && selectedRefinements.length === 0
    && activeSelectedRefinements.length === 0;

  const mobileLayout = (
    <div className="sui-flex sui-gap-2">
      <CancelButton onClick={onCancel} selectedRefinements={selectedRefinements} />
      <ApplyButton
        onClick={onApply}
        disabled={isDisabled}
        label={buttonLabel}
      />
    </div>
  );

  const desktopLayout = (
    <div className="sui-flex-col sui-flex sui-gap-4">
      <ApplyButton
        onClick={onApply}
        disabled={isDisabled}
        label={buttonLabel}
      />
      <CancelButton onClick={onCancel} selectedRefinements={selectedRefinements} />
    </div>
  );

  return <DrawerFooter>{isMobile ? mobileLayout : desktopLayout}</DrawerFooter>;
};

CustomDrawerFooter.displayName = 'CustomDrawerFooter';
CustomDrawerFooter.propTypes = {
  customRangeEntered: PropTypes.number.isRequired,
  selectedRefinements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeSelectedRefinements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCancel: func.isRequired,
  onApply: func.isRequired,
  footerLabel: PropTypes.bool.isRequired
};