import React, { useState } from 'react';
import PropTypes, {
  string, arrayOf, shape, bool, func
} from 'prop-types';
import { Drawer } from '@one-thd/sui-atomic-components';
import PrimaryFilterDrawerDimension from './PrimaryFilterDrawerDimension';
import { DrawerRefinement } from './DrawerRefinement';

const PrimaryFiltersDrawer = ({
  appliedDimensions,
  isOpen,
  activeDimension,
  onCloseDrawer,
  onDimensionChange,
  canonicalUrl,
  baseUrl
}) => {
  const [savedRefinements, setSavedRefinements] = useState([]);

  const handleRefinementsUpdate = (refinements) => {
    setSavedRefinements(refinements);
  };

  const cancel = (item, name) => {
    if (item?.currentTarget) {
      return false;
    }
    onCloseDrawer(false, activeDimension, item, name);
    return true;
  };

  const handleClickOutside = () => {
    cancel(savedRefinements, 'close');
  };

  const handleDimensionChange = ({ refinement, dimension }) => {
    onDimensionChange({ refinement, dimension });
  };

  return (
    <Drawer
      open={isOpen}
      onClose={cancel}
      onBackdropClick={handleClickOutside}
      position="left"
      data-testid="drawer"
    >
      {
        activeDimension.label === 'Category'
          ? (
            <DrawerRefinement
              key={`primary-filters-rerfinement-${activeDimension.label}`}
              dimension={activeDimension}
              onItemSelection={handleDimensionChange}
              onCancel={cancel}
            />
          )
          : (
            <PrimaryFilterDrawerDimension
              name={activeDimension.label}
              key={`primary-filter-item${activeDimension.label}`}
              dimension={activeDimension}
              appliedDimensions={appliedDimensions}
              onCancel={cancel}
              onDimensionChange={handleDimensionChange}
              canonicalUrl={canonicalUrl}
              baseUrl={baseUrl}
              onRefinementsUpdate={handleRefinementsUpdate}
            />
          )
      }
    </Drawer>
  );
};

PrimaryFiltersDrawer.displayName = 'PrimaryFiltersDrawer';
PrimaryFiltersDrawer.propTypes = {
  baseUrl: string.isRequired,
  canonicalUrl: string.isRequired,
  appliedDimensions: arrayOf(shape({})).isRequired,
  isOpen: bool.isRequired,
  activeDimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  onCloseDrawer: func.isRequired,
  onDimensionChange: func.isRequired
};
export { PrimaryFiltersDrawer };