import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmedFilled } from '@one-thd/sui-icons';
import { ProjectToggle } from './ProjectToggle';
import { TOTAL_ESTIMATED_BELOW } from '../../../../constants';

export const EstimatedWidget = ({
  pageType,
  onClick,
  coverage,
  numberOfEntries
}) => {
  return (
    <div
      data-testid="estimated-widget"
      className="sui-flex sui-flex-col sui-bg-primary sui-p-4 md:sui-basis-5/12"
    >
      <div className="sui-flex sui-flex-row sui-gap-2">
        <ConfirmedFilled color="success" />
        <h4 className="sui-leading-tight sui-font-bold sui-text-xl">
          {TOTAL_ESTIMATED_BELOW}
        </h4>
      </div>
      <div className="sui-flex sui-flex-row sui-justify-between">
        <div className="sui-flex sui-flex-col">
          <div
            id="estimated-widget-coverage-info"
            className="sui-flex sui-flex-row sui-gap-2"
          >
            <span>
              {numberOfEntries} Area{numberOfEntries > 1 ? 's' : ''}:
            </span>
            <span className="sui-font-bold">
              {coverage} sq. ft.
            </span>
          </div>
          <button
            type="button"
            onClick={onClick}
            className="sui-underline sui-text-nowrap"
          >
            + Add or Edit Area
          </button>
        </div>
        <ProjectToggle pageType={pageType} />
      </div>
    </div>
  );
};

EstimatedWidget.propTypes = {
  pageType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  coverage: PropTypes.number.isRequired,
  numberOfEntries: PropTypes.number.isRequired
};

EstimatedWidget.displayName = 'EstimatedWidget';