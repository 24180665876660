import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@one-thd/sui-atomic-components';
import PriceRangeInput from './CustomPriceRange/PriceRangeInput';
import { getMinValueFromLabel, getMaxValueFromLabel } from '../../product-results-helpers';

const PriceRangeRefinement = (props) => {
  const [openMinItemList, setOpenMinItemList] = useState(false);
  const anchorRefMin = useRef(null);

  const [openMaxItemList, setOpenMaxItemList] = useState(false);
  const anchorRefMax = useRef(null);

  const [lowerBound, setLowerBound] = useState('');
  const [upperBound, setUpperBound] = useState('');

  const {
    onChange, dimension, isPrimaryFilter, revision, isLowerBoundEntered, isUpperBoundEntered
  } = props;

  const { refinements } = dimension;

  const clearInputFields = () => {
    setLowerBound('');
    setUpperBound('');
  };

  const isBtnDisabled = useCallback(() => {
    return !(lowerBound.length || upperBound.length) || upperBound === '0';
  }, [lowerBound, upperBound]);

  const handlePriceRangeApply = useCallback((event) => {
    if (event) {
      event.preventDefault();
    }
    if (!isBtnDisabled()) {
      onChange({
        refinement: {
          lowerbound: lowerBound,
          upperbound: upperBound,
          urlSearchParams: window.location.search,
          label: `$${lowerBound} - $${upperBound}`,
          deselectUrl: '?',
          refinementKey: null
        },
        dimension: {
          dimensionId: 'custom_price',
          label: 'Price'
        }
      });
      clearInputFields();
    }
  }, [lowerBound, upperBound, isBtnDisabled, onChange]);

  const onFocusMinInput = useCallback((event) => {
    event.preventDefault();
    setOpenMinItemList((prev) => !prev);
  }, []);

  const onFocusMaxInput = useCallback((event) => {
    event.preventDefault();
    setOpenMaxItemList((prev) => !prev);
  }, []);

  const handleOnBlur = useCallback((event) => {
    event.preventDefault();
    setOpenMinItemList(false);
    setOpenMaxItemList(false);
  }, []);

  const handleScroll = (name) => {
    if (name === 'lowerBound') {
      setOpenMinItemList(false);
    } else {
      setOpenMaxItemList(false);
    }
  };

  const handleMinDropdownClick = useCallback((event) => {
    event.preventDefault();
    const minPriceInputDropdownValue = event.target.innerHTML.substring(1);
    setLowerBound(minPriceInputDropdownValue);
    if (isPrimaryFilter) {
      isLowerBoundEntered(minPriceInputDropdownValue);
    }
    handleScroll('lowerBound');
  }, [isLowerBoundEntered, isPrimaryFilter]);

  const handleMaxDropdownClick = useCallback((event) => {
    event.preventDefault();
    const maxPriceInputDropdownValue = event.target.innerHTML.substring(1);
    setUpperBound(maxPriceInputDropdownValue);
    handleOnBlur(event);
    if (isPrimaryFilter) {
      isUpperBoundEntered(maxPriceInputDropdownValue);
    }
    handleScroll('upperBound');
  }, [handleOnBlur, isUpperBoundEntered, isPrimaryFilter]);

  // to clear values of min-max container in primary filters
  useEffect(() => {
    clearInputFields();
  }, [revision]);

  // to make Apply btn clickable present in primary filters
  const handleMinValue = (event) => {
    let minPriceInputValue = (event.target.value || '').replace(/\D/, '');
    if (minPriceInputValue.length > 5) {
      minPriceInputValue = minPriceInputValue.slice(0, 5);
    }
    setLowerBound(minPriceInputValue);
    if (isPrimaryFilter) {
      isLowerBoundEntered(minPriceInputValue);
    }
  };

  // to make Apply btn clickable present in primary filters
  const handleMaxValue = (event) => {
    let maxPriceInputValue = (event.target.value || '').replace(/\D/, '');
    if (maxPriceInputValue.length > 5) {
      maxPriceInputValue = maxPriceInputValue.slice(0, 5);
    }
    setUpperBound(maxPriceInputValue);
    if (isPrimaryFilter) {
      isUpperBoundEntered(maxPriceInputValue);
    }
  };

  return (
    <>
      <form
        className="sui-flex sui-mb-5"
        autoComplete="off"
        noValidate
      >
        <PriceRangeInput
          id="pf-price-range-lowerBound"
          data-testid="pf-price-range-lowerBound"
          placeholder="$ Min"
          name="lowerBound"
          value={lowerBound}
          onChange={handleMinValue}
          onFocus={onFocusMinInput}
          onBlur={handleOnBlur}
          open={openMinItemList}
          anchorRef={anchorRefMin}
          onScrollDropDown={handleScroll}
          onClickOfDropDown={handleMinDropdownClick}
          getValue={getMinValueFromLabel}
          items={refinements}
          lowerBound={lowerBound}
        />

        <span className="sui-px-4 sui-flex sui-items-center">to</span>

        <PriceRangeInput
          id="pf-price-range-upperBound"
          data-testid="pf-price-range-upperBound"
          placeholder="$ Max"
          name="upperBound"
          value={upperBound}
          onChange={handleMaxValue}
          onFocus={onFocusMaxInput}
          onBlur={handleOnBlur}
          open={openMaxItemList}
          anchorRef={anchorRefMax}
          onScrollDropDown={handleScroll}
          onClickOfDropDown={handleMaxDropdownClick}
          getValue={getMaxValueFromLabel}
          items={refinements}
          lowerBound={lowerBound}
        />
        { !isPrimaryFilter && (
          <div className="sui-ml-4">
            <Button
              variant="tertiary"
              disabled={isBtnDisabled()}
              onClick={handlePriceRangeApply}
              data-testid="custom-priceRange-applyBtn"
            >
              Apply
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

PriceRangeRefinement.propTypes = {
  dimension: PropTypes.shape({
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    )
  }),
  isPrimaryFilter: PropTypes.bool,
  revision: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isLowerBoundEntered: PropTypes.func,
  isUpperBoundEntered: PropTypes.func
};

PriceRangeRefinement.defaultProps = {
  dimension: null,
  isPrimaryFilter: false,
  revision: 0,
  isLowerBoundEntered: null,
  isUpperBoundEntered: null
};

PriceRangeRefinement.displayName = 'PriceRangeRefinement';

export { PriceRangeRefinement };