import {
  getContentfulTargetingIds,
  useVariationIds
} from '@thd-olt-component-react/personalization-utils';
import { componentMapper } from '../utils/componentMapper';
import { parseContent } from '../utils/utils';

export const HybridLayoutContainer = (data, loading, error, slug,
  customerType,
  instance,
  storeId) => {

  const layouts = data?.layouts;
  const content = layouts?.content;

  const { mboxes, campaignIds, innervateTags } = getContentfulTargetingIds({ content });
  const isReady = mboxes.length > 0 || campaignIds.length > 0 || innervateTags.length > 0;
  const { variationIds, renderDefault } = useVariationIds({
    mboxes,
    campaignIds,
    innervateTags,
    isReady
  });

  if (!data || loading || error) {
    return {};
  }

  const customProps = {
    ComponentSelector: (component) => ({
      componentMap: componentMapper.getComponentMap(),
      defaultComponent: component.defaultComponent,
      variations: component.variations,
      variationIds: variationIds || [],
      renderDefault,
      isSeoBot: instance?.thdSEOBotDetection,
      targetingType: component.targetingType,
      customPropMap: {
        Spotlight: {
          showDescription: true
        },
        SpotlightContainer: {
          showDescription: true
        },
        Hero: {
          hasSidenav: true
        },
        EndCap: {
          storeId,
          customerType: customerType.toUpperCase(),
          pageUrl: `/${slug}`,
          narrowBottom: true
        }
      }
    }),
    Spotlight: () => ({
      showDescription: true,
      shouldUseContentPlayer: true
    }),
    SpotlightContainer: () => ({
      showDescription: true
    }),
    Resource: () => ({
      shouldUseContentPlayer: true
    }),
    PromoVisualNavigation: () => ({
      experienceHasSideNav: false
    }),
    EndCap: () => ({
      storeId,
      customerType: customerType.toUpperCase(),
      pageUrl: `/${slug}`,
      narrowBottom: true
    })
  };

  // * Get the parsed data from contentful and set it in a state
  const getParsedContent = (_data) => {
    const { Content } = parseContent({
      data: _data,
      componentMapper,
      customProps
    });

    return Content;
  };

  const contentElements = data ? getParsedContent(data) : null;

  return { contentElements, data, loading, error };
};
