import React, {
  useContext,
  useState
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox, ToggleSwitch } from '@thd-olt-component-react/checkbox';
import { StoreSelector } from '../StoreSelector/StoreSelector';
import { ProductResultsContext } from '../ProductResultsContext';
import '../../styles/refinement.scss';
import OnDisplayContext from '../OnDisplayContext';
import { storeLifecycleEvent } from '../../product-results-helpers';

const OnDisplayRefinement = ({
  checkboxClassName,
  id,
  refinement,
  enableMultiStore,
  toggleSwitch,
  multiStoreSelection,
  onDisplayData,
  multiFacet,
  parentID,
  onChange
}) => {
  const { onDisplay, onDisplayChange } = onDisplayData;
  const { data } = useContext(ProductResultsContext);
  const { onDisplayClick } = useContext(OnDisplayContext);

  const onDisplaySelected = onDisplay === 'true';

  const isChecked = parentID === 'leftNav' ? onDisplaySelected : onDisplayClick;

  const [multiStoreIds, setMultiStoreIds] = useState(multiStoreSelection ? [...multiStoreSelection] : []);

  const handleOnChange = (evt) => {
    // when onDisplay filters is selected/removed from leftNav/hortizontal-getItFast
    if (onDisplayChange) {
      storeLifecycleEvent('filters-refinements-visualNav.click', {
        dimensionName: 'Get It Fast',
        label: 'On Display',
        refinementLabel: 'On Display',
        refinementKey: '',
        selected: onDisplay === 'false' ? null : true
      });
      if (parentID === 'leftNav') {
        return (!!data && onDisplay === 'false') ? onDisplayChange({ useStoreFilter: 'true', multiStoreIds })
          : onDisplayChange({ useStoreFilter: 'false', multiStoreIds });
      }
    }
    onChange({ refinement, isChecked: evt.target.checked });
    return null;
  };

  const multiStoreCallback = ({ stores }) => {
    const idCount = multiStoreIds?.length;
    const newMultiStoreIds = stores.filter((store) => store.checked)
      .map((checkedStore) => parseInt(checkedStore.storeId, 10));
    if (newMultiStoreIds?.length < idCount) {
      onDisplayChange({ useStoreFilter: onDisplaySelected ? 'true' : 'false', multiStoreIds: newMultiStoreIds });
    }
    setMultiStoreIds(newMultiStoreIds);
  };

  const generateRefinementLabel = () => {
    return onDisplayData.itemCount > 0
      ? `${onDisplayData.itemCount} items on display at Store Today `
      : 'On Display at Store Today';
  };

  const labelClassName = classNames('refinement__link', {
    'refinement__link--special-value': refinement.label === 'Special Values'
  });

  const toggleSwitchClasses = classNames(
    'refinement__toggle-switch',
    'no-padding',
    {
      'padding-right': multiFacet
    });

  return (
    <>
      {!toggleSwitch && (
        <Checkbox
          className={checkboxClassName}
          disabled={parseInt(onDisplayData.itemCount, 10) === 0}
          id={`${id}-${parentID}`}
          key={`${id}-${parentID}`}
          data-refinement="On Display"
          data-refinementvalue="On Display"
          data-active="yes"
          data-omni="true"
          onChange={handleOnChange}
          checked={isChecked}
          value={refinement.refinementKey || ''}
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <h3 className={labelClassName} onChange={handleOnChange}>{generateRefinementLabel()}</h3>
        </Checkbox>
      )}
      {toggleSwitch && (
        <ToggleSwitch
          className={toggleSwitchClasses}
          id={id}
          key={id}
          onChange={handleOnChange}
          checked={isChecked}
        >
          <div className={labelClassName}>{refinement.label}</div>
        </ToggleSwitch>
      )}
      {refinement.label.toUpperCase() === 'PICK UP TODAY' && enableMultiStore && (
        <StoreSelector multiStoreCallback={multiStoreCallback} multiStoreSelection={multiStoreSelection} />
      )}
    </>
  );
};

OnDisplayRefinement.propTypes = {
  checkboxClassName: PropTypes.string,
  dimension: PropTypes.shape({}),
  id: PropTypes.string,
  enableMultiStore: PropTypes.bool,
  multiStoreSelection: PropTypes.string,
  refinement: PropTypes.shape({
    label: PropTypes.string,
    refinementKey: PropTypes.string,
    selected: PropTypes.bool,
    url: PropTypes.string
  }),
  onDisplayData: PropTypes.shape({
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func,
    itemCount: PropTypes.string,
    isStoreDisplay: PropTypes.string
  }),
  toggleSwitch: PropTypes.bool,
  multiFacet: PropTypes.bool,
  parentID: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

OnDisplayRefinement.defaultProps = {
  checkboxClassName: null,
  dimension: null,
  id: null,
  enableMultiStore: false,
  multiStoreSelection: null,
  refinement: null,
  onDisplayData: {
    onDisplay: 'false',
    onDisplayChange: null,
    itemCount: null,
    isStoreDisplay: 'false'
  },
  toggleSwitch: false,
  multiFacet: false,
  parentID: ''
};

OnDisplayRefinement.displayName = 'OnDisplayRefinement';

export { OnDisplayRefinement };
