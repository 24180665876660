import React from 'react';
import { bool, func, string } from 'prop-types';
import { useCalculatorLocalStorage, WidgetInputs } from '@thd-olt-component-react/calculator';
import { DefaultWidget } from './subcomponents/DefaultWidget';
import { EstimatedWidget } from './subcomponents/EstimatedWidget';

export const DrawerWidget = ({
  label, onClick, pageType, isVinylSheet
}) => {
  const { calculatorData, coverage } = useCalculatorLocalStorage({ label });
  const { entries } = calculatorData?.form || {};
  const showEstimatedWidget = (entries?.length || 0) > 0;

  return (
    <div
      className="sui-grow sui-flex"
    >
      {!showEstimatedWidget && (
        <DefaultWidget isVinylSheet={isVinylSheet} />
      )}
      {showEstimatedWidget && (
        <EstimatedWidget
          coverage={coverage}
          numberOfEntries={entries.length}
          onClick={onClick}
          pageType={pageType}
        />
      )}
    </div>
  );
};

DrawerWidget.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired,
  pageType: string.isRequired,
  isVinylSheet: bool.isRequired
};
