/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import {
  string, arrayOf, shape, func
} from 'prop-types';
import { StarFilled } from '@one-thd/sui-icons';
import { Typography } from '@one-thd/sui-atomic-components';
import { extractRating } from '../../product-results-helpers';

const RatingRefinement = ({ dimension, onItemSelection, selectedRefinements }) => {

  const prevSelectedRating = selectedRefinements.length > 0
    ? selectedRefinements[selectedRefinements.length - 1].label : '';
  const [selectedStars, setSelectedStars] = useState(extractRating(prevSelectedRating));

  const onStarItemSelection = (index) => {
    const selectedIndex = index + 1;
    const isDeselecting = selectedIndex === selectedStars;

    // Handle deselection
    if (isDeselecting) {
      setSelectedStars(0);
      onItemSelection({
        dimension
      });
      return;
    }

    // Handle selection
    setSelectedStars(selectedIndex);
    const refinement = {
      ...dimension.refinements.find(
        (item) => item.label.includes(selectedIndex.toString())
      )
    };

    onItemSelection({ dimension, refinement });
  };

  const getRatingMessage = () => {
    if (selectedStars === 5) {
      return '5 stars';
    }
    if (selectedStars > 0) {
      return `${selectedStars} stars and up`;
    }
    return 'Please choose a rating';
  };

  return (
    <section className="sui-mt-1 sui-cursor-pointer sui-text-center" data-testid="rating-refinement">
      {(() => {
        const stars = [];
        for (let index = 0; index < 5; index += 1) {
          stars.push(
            <section
              className="sui-mr-3 sui-inline-block"
              key={index}
              onClick={() => onStarItemSelection(index)}
            >
              {index < selectedStars
                ? <StarFilled size="large" color="brand" />
                : <StarFilled size="large" color="subtle" />}
            </section>
          );
        }
        return stars;
      })()}
      {/* Display text based on the number of selected stars */}
      <div className="sui-mt-3">
        <Typography>
          {getRatingMessage()}
        </Typography>
      </div>
    </section>
  );
};

RatingRefinement.displayName = 'RatingRefinement';
RatingRefinement.propTypes = {
  dimension: shape({
    dimensionId: string,
    label: string,
    refinements: arrayOf(
      shape({})
    )
  }).isRequired,
  onItemSelection: func.isRequired,
  selectedRefinements: arrayOf(shape({})).isRequired
};

export { RatingRefinement };