import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@one-thd/sui-atomic-components';
import { getMinValueFromLabel, getMaxValueFromLabel } from '../../../product-results-helpers';

const filterPriceItems = (items, getValue, lowerBound) => {
  if (getValue === getMaxValueFromLabel && lowerBound) {
    return items.filter((item) => parseFloat(getMaxValueFromLabel(item.label)) > parseFloat(lowerBound)
    );
  }
  return items.filter((item) => item.label.includes('-'));
};

const PriceRangeMenu = ({ items, onClickOfDropDown, getValue, lowerBound }) => {
  const filteredItems = filterPriceItems(items, getValue, lowerBound);
  return (
    <>
      {filteredItems.map((_item) => (
        <MenuItem
          key={`price-${_item.label}`}
          id={getValue === getMinValueFromLabel ? 'lowerBound' : 'upperBound'}
          onMouseDown={onClickOfDropDown}
        >
          {getValue === getMaxValueFromLabel ? `$${getValue(_item.label)}` : getValue(_item.label)}
        </MenuItem>
      ))}
    </>
  );
};

PriceRangeMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired
  })).isRequired,
  onClickOfDropDown: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  lowerBound: PropTypes.string.isRequired
};

export default PriceRangeMenu;