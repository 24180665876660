import React, { useEffect } from 'react';
import { string, bool } from 'prop-types';
import {
  params, shape, arrayOf,
  namedFragment, typename,
  string as stringType, useDataModel, customType
} from '@thd-nucleus/data-sources';
import { NewspaperRenderer } from './NewspaperRenderer';

const NewspaperModule = ({
  componentId,
  componentClass,
  lazyLoad = false
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('newspaper-module.ready');
  },
  []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  if (!data) {
    return null;
  }

  return (
    <NewspaperRenderer
      componentId={componentId}
      componentClass={componentClass}
      lazyLoad={lazyLoad}
      dataComponent={data?.component}
      richText={data?.component?.description}
    />
  );
};

NewspaperModule.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  lazyLoad: bool
};

NewspaperModule.displayName = 'NewspaperModule';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  id: stringType(),
  __typename: typename('DamMedia')
});

NewspaperModule.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Newspaper']).isRequired()
  }).shape({
    Newspaper: namedFragment({
      inline: true,
      fragmentType: 'Newspaper'
    }).shape({
      id: stringType(),
      altText: stringType(),
      link: stringType(),
      description: stringType(),
      damImageAlignmentBehavior: stringType(),
      damImageWidthBehavior: stringType(),
      descriptionAlignment: stringType(),
      desktopImage: DamMediaFragment,
      tabletImage: DamMediaFragment,
      mobileImage: DamMediaFragment
    })
  })
};

export { NewspaperModule };
