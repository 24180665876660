import React, { useRef, useState } from 'react';
import { bool } from 'prop-types';
import {
  Accordion, AccordionBody, AccordionHeader, Typography, Link, IconButton, Popover, useBreakpoint
} from '@one-thd/sui-atomic-components';
import { useSessionStorage } from '@thd-olt-global/thd-storage-utils';
import { Calculator, Info } from '@one-thd/sui-icons';
import { WidgetInputs } from '@thd-olt-component-react/calculator';
import { VINYL_SHEET_POPOVER_MESSAGE, PLAN_YOUR_PROJECT } from '../../../../constants';

export const DefaultWidget = ({ isVinylSheet }) => {
  const anchorRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [toggled, setToggled] = useSessionStorage('flooringPicker_projectPrice');
  const { lesser: isMobile } = useBreakpoint('md');
  const id = tooltipOpen ? 'fixed-width-popover' : undefined;

  const AccordionWrapper = isMobile ? Accordion : React.Fragment;
  const HeaderWrapper = isMobile ? AccordionHeader : React.Fragment;
  const BodyWrapper = isMobile ? AccordionBody : React.Fragment;

  const handleSuccess = () => {
    setToggled(true);
  };

  return (
    <div
      className="sui-grow sui-bg-primary sui-p-2 sui-flex sui-flex-col md:sui-p-4 md:sui-gap-16 md:sui-flex-row"
      data-testid="default-widget"
    >
      <AccordionWrapper
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isMobile ? { noBorders: true } : {})}
      >
        <HeaderWrapper>
          <div className="sui-flex sui-flex-row sui-self-stretch sui-gap-4">
            <div className="sui-mt-1">
              <Calculator size="large" />
            </div>
            <div className="sui-flex sui-flex-col md:sui-w-min">
              <h4 className="sui-leading-tight sui-font-bold sui-text-xl sui-text-nowrap">
                {PLAN_YOUR_PROJECT}
              </h4>
              <Typography variant="body-xs">
                Enter your measurements to view price per sq. ft.
                {isVinylSheet && (
                  <>
                    <IconButton
                      ref={anchorRef}
                      aria-describedby={id}
                      onClick={() => setTooltipOpen((value) => !value)}
                      icon={Info}
                    />
                    <Popover
                      id={id}
                      open={tooltipOpen}
                      anchorEl={anchorRef.current}
                      onClose={() => setTooltipOpen(false)}
                      placement="bottom"
                      title="Measuring for Vinyl Sheet"
                    >
                      <Typography variant="body-xs">
                        {VINYL_SHEET_POPOVER_MESSAGE}
                      </Typography>
                      <div className="sui-mt-2">
                        <Link
                          // eslint-disable-next-line max-len
                          href="https://images.thdstatic.com/catalog/pdfImages/d4/d4bbe90b-74b5-4ad2-b4e8-5a294fb14db7.pdf"
                          target="_blank"
                          variant="body-xs"
                        >
                          See our vinyl sheet measuring guide.
                        </Link>
                      </div>
                    </Popover>
                  </>
                )}
              </Typography>
            </div>
          </div>
        </HeaderWrapper>
        <BodyWrapper>
          <WidgetInputs onSuccess={handleSuccess} />
        </BodyWrapper>
      </AccordionWrapper>
    </div>
  );
};
DefaultWidget.propTypes = {
  isVinylSheet: bool.isRequired,
};

DefaultWidget.displayName = 'DefaultWidget';